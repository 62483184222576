import React,{useEffect, useState} from 'react';
import {  Avatar, Typography, Button, Modal,  Upload, message, Select,Badge,notification } from "antd";
import { useDispatch } from "react-redux";
import { EditOutlined, UploadOutlined, BgColorsOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {getProfileApi, sendProfilePhotoApi,sendProfileBackPhotoApi,editColorApi, editProfileMessageApi} from "../services/profile";
import { LocalStorageService } from "../helpers/LocalStorage";
import ImageUploadingButton from '../components/ImageUploadingButton';
import {setProfiles} from "../reducers/userSlice";

function MyProfile() {
    const dispatch = useDispatch();
    const { Title, Text } = Typography;
    const { Option } = Select;
    const [profileBackground, setProfileBackground] = useState<any>("#f1f1f1"); 
    const [colorWrite, setColorWrite] = useState(""); 
    const [isStatusModalVisible, setIsStatusModalVisible] = useState<any>(false);
    const [isProfileModal, setIsProfileModal] = useState<any>(false);
    const [isBackgroundModalVisible, setIsBackgroundModalVisible] = useState<any>(false);
    const [status, setStatus] = useState<any>("");
    const [newStatus, setNewStatus] = useState<any>("");
    const [profile, setProfile] = useState<any>([]);
    const [profileImage, setProfileImage] = useState<any>(null);
    const [po, setPo] = useState<any>(null);

    const userName:any = LocalStorageService.getUserName();
    const role:any = LocalStorageService.getRole();
   
    const getProfile = async () => {
        try{
         const response:any = await getProfileApi();
         if(response.status === 200 ){
             dispatch(setProfiles(response.data));
             setProfile(response.data)
             setStatus(response?.data?.profileMessage);
             setColorWrite(response?.data?.profileMessageColor)
             setPo(response?.data?.profileImageLink)
            if (response.data.profileBackgroundLink) {
                const imageResponse = await fetch(response.data.profileBackgroundLink);
                const blob = await imageResponse.blob(); 
                const reader: FileReader = new FileReader();
                reader.onloadend = (e: ProgressEvent<FileReader>) => {
                    setProfileBackground(`url(${e.target?.result})`);
                };
                reader.onerror = () => {
                    message.error("Arkaplan yüklenirken bir hata oluştu!");
                };
                reader.readAsDataURL(blob); // Blob'u Base64'e çevir
               
            }
         }
        }catch(e:any){
         console.log(e);
         
        }
     }

     const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<File> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
    
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target?.result as string;
    
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    let width = img.width;
                    let height = img.height;
    
                    // Oranı koruyarak yeniden boyutlandır
                    if (width > maxWidth || height > maxHeight) {
                        const aspectRatio = width / height;
                        if (width > height) {
                            width = maxWidth;
                            height = maxWidth / aspectRatio;
                        } else {
                            height = maxHeight;
                            width = maxHeight * aspectRatio;
                        }
                    }
    
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext("2d");
                    ctx?.drawImage(img, 0, 0, width, height);
    
                    canvas.toBlob((blob) => {
                        if (blob) {
                            resolve(new File([blob], file.name, { type: "image/jpeg" }));
                        } else {
                            reject(new Error("Resmi yeniden boyutlandırma başarısız oldu."));
                        }
                    }, "image/jpeg", 0.8); // Kaliteyi %80 yap (JPEG sıkıştırma)
                };
    
                img.onerror = (error) => reject(error);
            };
    
            reader.onerror = (error) => reject(error);
        });
    };

    const handleBackgroundUpload = async (info: any) => {
        const file = info.file;
    
        try {
            // Resmi 800x600 olarak yeniden boyutlandır
            const resizedFile = await resizeImage(file, 814, 190);
            
            // API'ye gönderilecek FormData oluştur
            const formData = new FormData();
            formData.append("File", resizedFile);
    
            // API'ye gönder
            const response: any = await sendProfileBackPhotoApi(formData);
            if (response.status === 204) {
                notification.success({ message: "Profil resmi başarıyla yüklendi!" });
                window.location.reload();
            }
        } catch (error: any) {
            console.log("hata", error);
            if (error.status === 400) {
                notification.error({ message: "Desteklenmeyen dosya uzantısı. Sadece JPEG, JPG veya PNG formatları yüklenebilir." });
            }
            if (error.status === 503 || error.status === 413) {
                notification.error({ message: "Resim çok büyük! 512KB'den büyük olmamalıdır" });
            }
            setIsBackgroundModalVisible(false);
        }
    };
  
      const handleStatusUpdate = async () => {
        if (newStatus.trim()) setStatus(newStatus);
        try{
            const response:any = await editProfileMessageApi(newStatus);
            if(response.status === 204){
                setIsStatusModalVisible(false);
                setNewStatus("");
            }
        }catch(e:any){

        }
      };
    
      const handleColorChange = async (value:any) => {
        try {
            const response:any = await editColorApi({messageColor:value});
            if (response.status === 204) {
               setIsBackgroundModalVisible(false);
               notification.success({message:'Başarıyla arkaplan yazı renginiz güncellendi.'})
               window.location.reload();
            } 
        } catch (error:any) {
            console.log('hata',error)
        } 
      };

      // Handle profile image upload
    const handleProfileImageUpload = async (file: any) => {
        if (!file) return;
        try {
            const response:any = await sendProfilePhotoApi(file);
            if (response.status === 204) {
                notification.success({message: "Profil resmi başarıyla yüklendi!"});
                window.location.reload();
            } else {
                notification.error({message: "Profil resmi yüklenirken bir hata oluştu!"});
            }
        } catch (error) {
            console.log('hata',error)
            notification.error({message: "Profil resmi yüklenirken bir hata oluştu!"});
        }  
    };


    useEffect(() => {
        getProfile();
    },[])

    useEffect(() => {
        handleProfileImageUpload(profileImage);
    },[profileImage])


    return (
        <> 
            <div className="profile-out-div profile-background" 
                style={{ background: profileBackground, 
                backgroundSize: "contain", backgroundPosition: "center",
                width: "100%",height: "190px", backgroundRepeat: "no-repeat", objectFit:"cover" }}  >
                <div style={{ width: '120px', textAlign:'center' }}>
                   
                    {
                        profile?.profileImageLink === null ?
                        <Avatar
                        size={100}
                        icon={userName[0].charAt(0).toUpperCase()}
                        style={{ border: "2px solid ", borderColor:colorWrite }}
                    /> :
                        <Avatar
                            size={100}
                            src={profile?.profileImageLink} 
                            style={{ border: "2px solid ", borderColor:colorWrite  }}
                        />
                    }
                    <Button icon={<EditOutlined />} onClick={() => setIsProfileModal(true)} type="link" 
                    className='profile-ph-btn' >  
                         Düzenle
                     </Button>
                </div>
                <div style={{ width: '100%' }} className="profile-right">
                    <Title level={4} style={{ margin: 0, color: `${colorWrite === "" ? "#983961" : colorWrite}  ` }} className="profile-title">
                        Merhaba {userName}
                    </Title>
                    <Badge.Ribbon 
                        text={role === 'Administrator' ? 'Yönetici': 'Yeni Üye'} 
                        color="gold" 
                        style={{ top: '-40px', right: '-27px' }} 
                    />
                     {
                        profile?.dietClub && (
                            <Badge.Ribbon 
                            text={'Diyet Kulubü'} 
                            color="pink" 
                            style={{ top: '-10px', right: '-27px' }} 
                        />
                        )
                    }
                    {
                        profile?.momClub && (
                            <Badge.Ribbon 
                            text={'Anneler Kulubü'} 
                            color="red" 
                            style={{ top: '20px', right: '-27px' }} 
                        />
                        )
                    }
                    
                   
                    <div style={{ marginTop: "10px", color: `${colorWrite}` }}>
                        <Text strong style={{ color: `${colorWrite}` }}>{profile?.isNewMember ? 'Yeni Üye': 'Kayıtlı Üye'  }</Text> - 
                        <Text style={{ color: `${colorWrite}` }}>Katılım Tarihi: {profile?.registrationDate} </Text> - {" "}
                        <Text style={{ color: `${colorWrite}` }}>Son Görülme: {profile?.lastActivityDate} </Text>
                    </div>
                    <div style={{  width:'580px', height:'60px'}}>
                        <Text strong style={{ color: `${colorWrite}` }}>Durum:</Text> 
                        <Text style={{ color: `${colorWrite}` }}>{status}</Text>
                        <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => setIsStatusModalVisible(true)}
                            style={{ color: `${colorWrite}`, height:'14px', padding:'0' }}
                        >
                            Düzenle
                        </Button>
                    </div>
                    <div className="background-btn-div">
                        <Button
                            type="default"
                            icon={<BgColorsOutlined />}
                            onClick={() => setIsBackgroundModalVisible(true)}
                        >
                            Profil Arkaplanını Değiştir
                        </Button>
                    </div>

                </div>
            </div>

            <Modal
                title="Durumunuzu Güncelleyin"
                visible={isStatusModalVisible}
                onCancel={() => setIsStatusModalVisible(false)}
                onOk={handleStatusUpdate}
                okText="Güncelle"
                cancelText="İptal"
                className="status-modal-update"
            >
                <TextArea
                    placeholder="Yeni durumunuzu yazın..."
                    value={newStatus}
                    rows={5}
                    onChange={(e) => setNewStatus(e.target.value)}
                ></TextArea>
            </Modal>


            <Modal
                title="Profil Arkaplanını Düzenle"
                visible={isBackgroundModalVisible}
                onCancel={() => setIsBackgroundModalVisible(false)}
                footer={null}
                className="profile-modal-change"
            >
                <p>
                Profil ekran görüntünüzü değiştirmek isterseniz, aşağıdaki düğmeyi kullanarak 3:1 oranında dikdörtgen bir görsel yükleyebilirsiniz. Net ve uyumlu bir görünüm için uygun boyutta bir görsel seçmenizi öneririz.
                </p>
                <Upload
                    beforeUpload={() => false}
                    onChange={handleBackgroundUpload}
                    showUploadList={false}
                >
                    <Button icon={<UploadOutlined />}>Yeni Arkaplan Yükle</Button>
                </Upload>
                <p>
                Profil ekran görüntünüzdeki yazı rengini değiştirmek isterseniz, aşağıdaki listeden dilediğiniz rengi seçebilirsiniz. Görünürlüğü artırmak için arka planınıza uygun bir renk tercih etmenizi öneririz.
                </p>
                <Select
                    defaultValue={colorWrite}
                    style={{ width: 200 }}
                    onChange={handleColorChange}
                >
                    <Option value="#ffffff">Beyaz</Option>
                    <Option value="#f5222d">Kırmızı</Option>
                    <Option value="#1890ff">Mavi</Option>
                    <Option value="#52c41a">Yeşil</Option>
                    <Option value="#faad14">Sarı</Option>
                    <Option value="#722ed1">Mor</Option>
                    <Option value="#000000">Siyah</Option>
                </Select>
            </Modal>
        
            <Modal
                visible={isProfileModal}
                onCancel={() => setIsProfileModal(false)}
                footer={null}
                className="profile-modal"
                maskClosable={false}
            >
                 <ImageUploadingButton onCroppedImage={setProfileImage} onClose={() => setIsProfileModal(false)} po={po}/>
            </Modal>
        </>
        
      
    );
}

export default MyProfile;
