import React, { useEffect, useState } from "react";
import { Form, Checkbox, Button, Typography, Divider, notification } from "antd";
import {getSecuritySettingsApi,editSecuritySettingsApi} from '../../services/profile';

const { Title } = Typography;

const PrivacySettings = () => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({
    showProfileToPublic: false,
    showOnlineStatus: false,
    showActivitiesToPublic: false,
});

  const getSecuritySettings = async () => {
    try{
      const response:any = await getSecuritySettingsApi();
      if(response.status === 200){
        setInitialValues({
          showProfileToPublic: Boolean(response?.data?.showProfileToPublic), 
          showOnlineStatus: Boolean(response?.data?.showOnlineStatus),
          showActivitiesToPublic: Boolean(response?.data?.showActivitiesToPublic),
      });
      form.setFieldsValue({
          showProfileToPublic: Boolean(response?.data?.showProfileToPublic),
          showOnlineStatus: Boolean(response?.data?.showOnlineStatus),
          showActivitiesToPublic: Boolean(response?.data?.showActivitiesToPublic),
      });
      }
    }catch(e:any){
      console.log(e)
    }
  }

  const onFinish = async (values:any) => {
    try{
      const response:any = await editSecuritySettingsApi(values);
      if(response.status === 204){
        notification.success({message: 'Başarıyla güncellenmiştir.'});
        getSecuritySettings()
      }
    }catch(e:any){
      console.log(e)
    }
  };

  useEffect(() => {
    getSecuritySettings()
  },[])

  return (
    <div>
      <Title level={5}>
        Gizlilik Ayarları
      </Title>
      <Divider />

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialValues} 
      >
        {/* Profil Görünürlüğü */}
        <Title level={4}>Profil Görünürlüğü</Title>
        <Form.Item name="showProfileToPublic" valuePropName="checked">
          <Checkbox>Profilim herkes tarafından görülebilir.</Checkbox>
        </Form.Item>

        <Divider />

        {/* Mesaj Alımı */}
        <Title level={4}>Çevrim içi Durumumu Göster</Title>
        <Form.Item name="showOnlineStatus" valuePropName="checked">
          <Checkbox>Çevrimiçi durumum herkes tarafından görülebilir.</Checkbox>
        </Form.Item>

        <Divider />

        {/* Aktivite Görünürlüğü */}
        <Title level={4}>Aktivite Görünürlüğü</Title>
        <Form.Item name="showActivitiesToPublic" valuePropName="checked">
          <Checkbox>Herkes tarafından faaliyetlerim görülebilir.</Checkbox>
        </Form.Item>

        {/* Kaydet Butonu */}
        <Divider />
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PrivacySettings;
