import React, { useState } from "react";
import { Layout, Menu,} from "antd";
import {useLocation,Link } from "react-router-dom";
import MyProfile from "../../components/MyProfile";
import ProfileMessages from "./ProfileMessages";
import MyTopics from "./MyTopics";
import TopicsFollow from "./TopicsFollow";
import NotificationsPage from "./NotificationsPage";


const Profile = () => {
  const { Sider, Content } = Layout;
  const location = useLocation();
  const confirmUrl = location.pathname.split('/');
  const page = confirmUrl[2];

  const [selectedMenu, setSelectedMenu] = useState<any>(page);

  const renderContent = () => {
    switch (selectedMenu) {
      case "mesajlarim":
        return <ProfileMessages/>;
      case "bildirimler":
        return <NotificationsPage/>;
      case "actigim-konular":
        return  <MyTopics/>;
      default:
        return <TopicsFollow/>;
    }
  };

  const getSelectedKey = () => {
    if (location.pathname.includes("mesajlarim")) {
      return "mesajlarim";
    } else if (location.pathname.includes("bildirimler")) {
      return "bildirimler";
    } else if (location.pathname.includes("actigim-konular")) {
      return "actigim-konular";
    }
    return "takip-etttigim-konular";
  };

  return (
    <Layout style={{ minHeight: "100vh", marginTop:'20px' }}>
      <MyProfile/>
      <Layout>
        <Sider width={200} style={{ background: "#fff" }}>
          <Menu
            mode="inline"
            defaultSelectedKeys={["mesajlarim"]}
            style={{ height: "100%", borderRight: 0, paddingTop:'35px' }}
            selectedKeys={[getSelectedKey()]}
            onClick={(e) => setSelectedMenu(e.key)}
          >
            <Menu.Item key="mesajlarim">
              <Link to="/profilim/mesajlarim" style={{ fontWeight: '600' }}>
              Tüm Mesajlarım
              </Link>
            </Menu.Item>
            <Menu.Item key="bildirimler">
              <Link to="/profilim/bildirimler" style={{ fontWeight: '600' }}>
              Tüm Bildirimler
              </Link>
            </Menu.Item>
            <Menu.Item key="actigim-konular">
              <Link to="/profilim/actigim-konular" style={{ fontWeight: '600' }}>
              Açtığım Konular
              </Link>
            </Menu.Item>
            <Menu.Item key="takip-etttigim-konular">
              <Link to="/profilim/takip-etttigim-konular" style={{ fontWeight: '600' }}>
                Takip Ettiğim Konularar
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ padding: "0 0 0 24px" }}>
          <Content
            style={{
              background: "#fff",
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
            className="profile-main"
          >
            {renderContent()}
          </Content>
        </Layout>
      </Layout>
        
   
    </Layout>
  );
};

export default Profile;
