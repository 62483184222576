import React, { useState } from 'react';
import { Card, Switch, Row, Col, Typography, Divider } from 'antd';
import { useSelector } from 'react-redux';

const { Title, Text } = Typography;

const notificationCategories = [
  {
    id: 1,
    name: 'Kadın ve Çocuk Sağlığı',
    description: 'Kadın ve Çocuk Sağlığı hakkında bildirim almak için bu seçeneği açın. almak için bu seçeneği açın.',
  },
  {
    id: 2,
    name: 'Diyet Kategori',
    description: 'Hesabınızla ilgili güvenlik uyarıları almak için bu seçeneği açın.',
  },
  {
    id: 3,
    name: 'Pazarlama ve Kampanyalar',
    description: 'Kampanya ve özel tekliflerden haberdar olmak için bildirimleri açın.',
  },
];

const NotificationSettingsPage = () => {
  const {topCategories} = useSelector((state: any) => state.topicForm);
  const [settings, setSettings] = useState(
    topCategories.reduce((acc:any, category:any) => {
      acc[category.categoryId] = true; // Başlangıçta tüm bildirimler açık
      return acc;
    }, {})
  );

 
  console.log('topCategories',topCategories)

  // Switch durumu değiştiğinde ayarı güncelleme fonksiyonu
  const handleSwitchChange = (id:any, checked:any) => {
    setSettings((prevSettings:any) => ({
      ...prevSettings,
      [id]: checked,
    }));
  };
console.log('settings',settings)
  return (
    <Card title="Bildirim Ayarları" style={{ maxWidth: 800, margin: '0 auto' }}>
      <Text type="secondary">İstediğiniz kategorilerden bildirim almayı seçebilirsiniz.</Text>
      <Divider />
      
      <Row gutter={[16, 16]}>
        {topCategories.map((category:any) => (
          <Col span={24} key={category.categoryId}>
            <Card>
              <Row justify="space-between" align="middle">
                <Col style={{ width:'90%', paddingLeft:'5px'}}>
                  <Title level={5} style={{ marginBottom: 0 }}>
                    {category.name}
                  </Title>
                  <Text type="secondary">{category.description}</Text>
                </Col>
                <Col style={{ width:'10%'}}>
                  <Switch
                    checked={settings[category.categoryId]}
                    onChange={(checked) => handleSwitchChange(category.categoryId, checked)}
                    checkedChildren="Açık"
                    unCheckedChildren="Kapalı"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default NotificationSettingsPage;