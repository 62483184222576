import React, { useEffect, useState } from "react";
import {  List, Typography, Button, Modal, Input, Avatar, Divider,Select, notification, Pagination } from "antd";
import { EyeOutlined, DeleteOutlined,ClockCircleOutlined,MessageOutlined } from '@ant-design/icons';
import moment from 'moment';
import {getMessageApi, sendMessageApi, getMessageUserNameApi, deleteMessageApi, searchUserApi} from '../../services/profile';
import { LocalStorageService } from "../../helpers/LocalStorage";

const { Text } = Typography;
const { TextArea } = Input;

const ProfileMessages = () => {
  const userName = LocalStorageService.getUserName();
  const userImage = LocalStorageService.getUserImage();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [messages, setMessages] = useState<any>([]);
  const [sendUser, setSendUser] = useState<any>('');
  const [chatModalVisible, setChatModalVisible] = useState<any>(false);
  const [newChatModalVisible, setNewChatModalVisible] = useState<any>(false);
  const [currentChat, setCurrentChat] = useState<any>([]);
  const [replyText, setReplyText] = useState<any>("");
  const [users, setUsers] = useState<any>([]);
  const [selectedInput, setSelectedInput] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>('');
  const [messageContent, setMessageContent] = useState<any>('');
  const [otherProfilePhoto, setOtherProfilePhoto] = useState<any>('');
  const [ownerProfilePhoto, setOwnerProfilePhoto] = useState<any>('');

  const getMessage = async () => {
    try{
      const response:any = await getMessageApi(currentPage,pageSize);
      if(response.status === 200){
        setMessages(response?.data?.messages)
      }
    }catch(e){
      console.log(e)
    }
  }

  const handleMessageChange = (e:any) => {
    setMessageContent(e.target.value);
  };

  const handleSendFirstMessage = async () => {
    if (!selectedUser) {
      notification.success({message: 'Lütfen bir kullanıcı seçin.'});
      return;
    }
    if (!messageContent) {
      notification.success({message: 'Mesaj içeriğini girin.'});
      return;
    }

    try{
      const response = await sendMessageApi(selectedUser, messageContent);
      if(response){
        setNewChatModalVisible(false);
        setSelectedUser('');
        setSelectedInput([]);
        setMessageContent('');
        getMessage();
      }
    }catch(e:any){
     console.log(e)
    }
  };

  const handleOpenChat = async (person:any) => {
    setSendUser(person.otherUsername);
    try{
      const response:any = await getMessageUserNameApi(person.otherUsername, currentPage, pageSize);
      console.log('mesajı göt res',response)
      if(response){
        setCurrentChat(response?.data?.messages?.reverse());
        setChatModalVisible(true);
        setOwnerProfilePhoto(response?.data?.ownerProfilePhoto);
        setOtherProfilePhoto(response?.data?.otherProfilePhoto)
      }
    }catch(e:any)
    { console.log(e)}
  };

  const handleSendMessage = async () => {
    if (!replyText.trim()) return;

    const newMessage = {
      senderUsername: userName,
      receiverUsername: sendUser,
      messageDate:moment().format('DD-MM-YYYY'),
      messageContent:replyText
    };

    setMessages([...messages, replyText]);
    setCurrentChat([...currentChat, newMessage]);
    setReplyText("");

    try{
      const response = await sendMessageApi(sendUser, replyText);
      if(response){
        getMessage();
        setReplyText("");
      }
    }catch(e:any){
     console.log(e)
    }
  };

  const handleDeleteMessage = async (person:any) => {
      try{
        const response:any = await deleteMessageApi(person.otherUsername);
        if(response){
          getMessage();
        }
      }catch(e:any){
        console.log(e)
      }
    }

  const handlePageChange = (page:any,size:any) => {
      setCurrentPage(page);
      setPageSize(size);
    };

  const handleSelectUser = (value:any) => {
    setSelectedUser(value)
  }

    useEffect(() => {
      
      if (selectedInput.length === 0) return; 
      if(selectedInput.length > 0){
        const searchUserData = async () => {
          try{
            const response:any = await searchUserApi(selectedInput);
            if(response){
              setUsers(response.data)
            }
          }catch(e:any){
            console.log(e)
          }
        };
        searchUserData();
      }
    }, [selectedInput]); 

  useEffect(() => {
    getMessage();
  },[pageSize, currentPage])

  return (
    <>
      <div className="profile-msg-main">
        <Button type="default" className='profile-top-btn' onClick={() => setNewChatModalVisible(true)} >
          <MessageOutlined/> Yeni Mesaj Oluştur
        </Button>
        <Divider/>
        {
          messages ? (
            <List
            style={{minHeight:'400px'}}
            dataSource={messages}
            renderItem={(person:any, index:any) => {
              return (
                <List.Item 
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="profile-msg-box">
                    <div style={{ display: "flex", alignItems: "center"}}>
                        {
                          userImage !== 'null' ? 
                            <Avatar   src={`https://api.kadincagundem.com/images/${person.otherUserProfilePhoto}`} />
                              :
                            <Avatar  icon={person?.otherUsername?.charAt(0).toUpperCase()} />
                        }
                      <div style={{ width:'100%'}}>
                        <span className="date" style={{float:'right'}}><ClockCircleOutlined /> {moment(person.lastMessageDate).format('DD-MM-YYYY')}</span>
                        <Text strong>{person.otherUsername}</Text>
                        <br />
                        <Text type="secondary">{person.lastMessagePreview}</Text>
                      </div>
                    </div>
                    <div  className="profile-msg-btn">
                      <Button type="primary" onClick={() => handleOpenChat(person)}>
                       <EyeOutlined/> Mesajı Gör
                      </Button>
                      <Button type="default" onClick={() => handleDeleteMessage(person)}>
                        <DeleteOutlined/> Tüm Konuşmayı Sil
                      </Button>
                    </div>
                  </div>
                </List.Item>
              );
            }}
          />
          ) : 'Mesajınız yok'
        }
         
            <div style={{ textAlign: "left", marginTop: "20px" }}>
              <Pagination
                className='ant-pagination-bottom'
                current={currentPage}
                pageSize={pageSize}
                total={10}
                onChange={handlePageChange}
                showSizeChanger={true}
                pageSizeOptions={['10', '20', '50', '100']} 
              />
          </div>
        </div>
 
      <Modal
        title={`${sendUser} ile sohbet penceresi..`}
        visible={chatModalVisible}
        onCancel={() => setChatModalVisible(false)}
        footer={null}
        className="profile-messages-modal"
        width={700}
        // bodyStyle={{height: 550}}
      >
        <div style={{ maxHeight: "400px", overflowY: "auto", padding: "10px 0" }}>
        {currentChat.map((msg: any, index: any) => {
          const isFirstMessageFromUser =
            index === 0 || currentChat[index - 1].receiverUsername !== msg.receiverUsername;

          return (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: msg.receiverUsername !== userName ? "flex-end" : "flex-start",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              {msg.receiverUsername === userName && isFirstMessageFromUser && (
                otherProfilePhoto !== 'null' ?
                <Avatar   src={`https://api.kadincagundem.com/images/${otherProfilePhoto}`}  />
                    :
                <Avatar icon={msg.receiverUsername.slice(0, 1).toUpperCase()} />  
              )}
              <div
                style={{
                  maxWidth: "70%",
                  padding: "10px 15px",
                  background: msg.receiverUsername !== userName ? "#983961" : "#f0f0f0",
                  color: msg.receiverUsername !== userName ? "#fff" : "#000",
                  borderRadius: "15px",
                  textAlign: "left",
                  marginRight: msg.receiverUsername !== userName && isFirstMessageFromUser ? "0" : "32px",
                  marginLeft: msg.receiverUsername === userName && isFirstMessageFromUser ? "0" : "32px"
                }}
                className={msg.receiverUsername !== userName ? "from-typography" : "incoming-typography"}
              >
                <Text>{msg.messageContent}</Text>
                <br />
                <Text type="secondary" style={{ fontSize: "12px" }}>
                  {msg.lastMessageDate}
                </Text>
              </div>
              {msg.receiverUsername !== userName && isFirstMessageFromUser && (
                ownerProfilePhoto !== 'null' ?
                    <Avatar   src={`https://api.kadincagundem.com/images/${ownerProfilePhoto}`}  />
                      :
                    <Avatar icon={msg.receiverUsername.slice(0, 1).toUpperCase()} />      
                
              )}
              
            </div>
          );
        })}

        </div>
        <div style={{ marginTop: "10px" }}>
          <TextArea
            rows={2}
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            placeholder="Mesajınızı yazın..."
          />
          <Button
            type="primary"
            onClick={handleSendMessage}
            style={{ marginTop: "10px", float: "right" }}
          >
            Gönder
          </Button>
        </div>
      </Modal>

      <Modal
        title="Yeni Mesaj Gönder"
        visible={newChatModalVisible}
        className="new-message-modal"
        onCancel={() => setNewChatModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setNewChatModalVisible(false)} >
            Kapat
          </Button>,
          <Button
            key="send"
            type="primary"
            onClick={handleSendFirstMessage}
            disabled={!selectedUser || !messageContent}
          >
            Gönder
          </Button>,
        ]}
    >
      <div>
        <Select
          showSearch
          style={{ width: '100%', marginBottom: 20 }}
          placeholder="Mesaj Göndereceğiniz Üyeyi seçiniz."
          optionFilterProp="children"
          onSearch={(e:any) => setSelectedInput(e)}
          onChange={handleSelectUser}
          value={selectedUser} 
        >
          {users && users.map((user:any) => (
            <Select.Option key={user.id} value={user}>
              {user}
            </Select.Option>
          ))}
        </Select>
        <TextArea
          rows={4}
          placeholder="Mesajınızı yazın"
          value={messageContent}
          onChange={handleMessageChange}
        />
      </div>
    </Modal>
   </>
  );
};

export default ProfileMessages;
