import React from "react";
import { Form, Input, DatePicker, Button, Row, Col, Card } from "antd";
import dayjs from "dayjs";

const MeasurementNotebook = () => {
  const [form] = Form.useForm();

  const onFinish = (values:any) => {
    console.log("Form values:", values);
  };

  return (
   
        <>
          <h2 style={{ textAlign: "center", marginBottom: "0", fontWeight: "bold" }}>
            Beden Ölçü Kayıt Defteri
          </h2>
          <span style={{fontSize:'12px'}}>Eğer diyetteyseniz istediğiniz aralıkta ölçülerinizi kayıt altına alarak ilerleyişinizi takip edebilirsiniz.</span>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              date: dayjs(),
            }}
          >
            <Form.Item
              label="Tarih"
              name="date"
              rules={[{ required: true, message: "Lütfen tarihi seçiniz!" }]}
            >
              <DatePicker
                style={{
                  width: "100%",
                  borderRadius: "8px",
                }}
                format="YYYY-MM-DD"
                placeholder="Tarih seçin"
              />
            </Form.Item>

            <Form.Item
              label="Kilo (kg)"
              name="weight"
              rules={[
                { required: true, message: "Lütfen kilonuzu giriniz!" },
                { pattern: /^\d+(\.\d{1,2})?$/, message: "Geçerli bir sayı giriniz!" },
              ]}
            >
              <Input placeholder="Kilonuzu girin" />
            </Form.Item>

            <Form.Item
              label="Göğüs (cm)"
              name="chest"
              rules={[
                { required: true, message: "Lütfen göğüs ölçünüzü giriniz!" },
                { pattern: /^\d+(\.\d{1,2})?$/, message: "Geçerli bir sayı giriniz!" },
              ]}
            >
              <Input placeholder="Göğüs ölçünüzü girin" />
            </Form.Item>

            <Form.Item
              label="Bel (cm)"
              name="waist"
              rules={[
                { required: true, message: "Lütfen bel ölçünüzü giriniz!" },
                { pattern: /^\d+(\.\d{1,2})?$/, message: "Geçerli bir sayı giriniz!" },
              ]}
            >
              <Input placeholder="Bel ölçünüzü girin" />
            </Form.Item>

            <Form.Item
              label="Basen (cm)"
              name="hips"
              rules={[
                { required: true, message: "Lütfen basen ölçünüzü giriniz!" },
                { pattern: /^\d+(\.\d{1,2})?$/, message: "Geçerli bir sayı giriniz!" },
              ]}
            >
              <Input placeholder="Basen ölçünüzü girin" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  width: "100%",
                  backgroundColor: "#983961",
                  borderColor: "#983961",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  height:'34px'
                }}
              >
                Kaydet
              </Button>
            </Form.Item>
          </Form>
        </>
    
  );
};

export default MeasurementNotebook;
