import React, { useEffect, useState } from 'react';
import { Input, Dropdown, Menu, Button, Form, Checkbox , Select} from 'antd';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from '@ant-design/icons';
import { getTopicsSearchApi, getTopicsSearchUserApi } from '../services/contents';
import { setSearchResult, setSearchCTotalDataCount } from '../reducers/topicForm';
import { searchUserApi} from '../services/profile';
import { debounce } from "lodash";

function SearchHeader() {
    const [formSearch] = Form.useForm();
    let navigate = useNavigate();
    const dispatch = useDispatch(); 
    const [visible, setVisible] = useState(false);
    const [check, setCheck ] = useState(false);
    const [queryTxt, setQueryTxt ] = useState('');
    const [selectedInput, setSelectedInput] = useState<any>([]);
    const [selectedUser, setSelectedUser] = useState<any>('');
    const [users, setUsers] = useState<any>([]);

    const {searchPageSize, searchCurrentPage} = useSelector((state: any) => state.topicForm);
  
    const handleVisibleChange = (flag:any) => {
      setVisible(flag);
    };

    const handleSelectUser = (value:any) => {
      setSelectedUser(value)
    }
  

    const searchFun = async (values:any) => {
      setQueryTxt(values.query !== undefined ? values.query : '' );
      const queryString =values?.usernames !== undefined && values?.usernames.map((user:any) => `usernames=${user}`).join("&");
      if(values.check){
        const response:any = await getTopicsSearchUserApi(queryString, searchPageSize, searchCurrentPage)
        if(response.status === 200){
          formSearch.resetFields(); 
          dispatch(setSearchResult(response?.data?.results));
          dispatch(setSearchCTotalDataCount(response?.totalDataCount))
          navigate(`arama-sonuclari/${'uyeler'.trim()}`);
          setVisible(false);
          setCheck(false);
        }
      }else{
        const response:any = await getTopicsSearchApi(values.query, searchPageSize, searchCurrentPage)
        if(response.status === 200){
          formSearch.resetFields(); 
          dispatch(setSearchResult(response?.data?.results));
          dispatch(setSearchCTotalDataCount(response?.totalDataCount))
          navigate(`arama-sonuclari/${values.query.trim()}`);
          setVisible(false);
          setCheck(false);
        }
      }
    }

  useEffect(() => {
    if(queryTxt !== ''){
      searchFun(queryTxt)
    }
  },[searchPageSize,searchCurrentPage])

  useEffect(() => {
      
    if (selectedInput.length === 0) return; 
    if(selectedInput.length > 0){
      const searchUserData = debounce(async () => {
        try {
          const response: any = await searchUserApi(selectedInput);
          if (response) {
            setUsers(response.data);
          }
        } catch (e: any) {
          console.log(e);
        }
      }, 300); 
    
      searchUserData();
    
      return () => searchUserData.cancel(); // Temizleme işlemi
    }
  }, [selectedInput]); 

    const menu = (
      <Menu>
        <Menu.Item>
            <Form 
            form={formSearch}
            layout="vertical"
            onFinish={searchFun}
            className='seach-form'
            >
              <Form.Item name='query'>
                <Input  placeholder='Arama yap...' disabled={check} />
                </Form.Item>
                <Form.Item name='check' valuePropName="checked">
                  <Checkbox onChange={() => setCheck(!check)}  >Sadece Kullanıcı Adıyla Ara</Checkbox>
                </Form.Item>
                {/* <span><i>ya da</i></span> */}
                <Form.Item name='usernames' style={{ marginTop:'5px'}}>
                  {/* <Input  placeholder="Kullanıcı adıyla ara..." name='userSearch' disabled={check}/> */}
                  <Select
                    disabled={!check} 
                    mode="multiple"
                    showSearch
                    style={{ width: '100%', marginBottom: 0, maxWidth:'260px', minWidth:'260px' }}
                    placeholder="Kullanıcı adıyla ara..."
                    optionFilterProp="children"
                    onSearch={(e:any) => setSelectedInput(e)}
                    onChange={handleSelectUser}
                    value={selectedUser} 
                  >
                    {users && users.map((user:any) => (
                      <Select.Option key={user.id} value={user}>
                        {user}
                      </Select.Option>
                    ))}
                  </Select>

                </Form.Item>
                <Form.Item style={{textAlign:'right', marginTop:'40px'}}>
                  <Button className='header-search-btn' htmlType="submit">Ara</Button>
                </Form.Item>
            </Form>
        </Menu.Item>
      </Menu>
    );

    return (
        <Dropdown
        overlay={menu}
        trigger={['click']}
        onVisibleChange={handleVisibleChange}
        visible={visible}
        placement="topCenter"
      >
        <Button
          icon={<SearchOutlined />}
          onClick={() => setVisible(!visible)}
        />
      </Dropdown>
    );
}

export default SearchHeader;
