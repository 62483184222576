import React, { useState } from "react";
import { Form, Input, DatePicker, Checkbox, Select, Button, Typography, Divider, Row, Col } from "antd";
import { useSelector } from "react-redux";

const { Title, Text } = Typography;
const { Option } = Select;


const AccountSettings = () => {
  const [showBirthYearCheckbox, setShowBirthYearCheckbox] = useState(false);
  const [joinDietClub, setJoinDietClub] = useState(false);

  const {profile} = useSelector((state: any) => state.user);
  console.log('profile',profile)

  const onFinish = (values:any) => {
    console.log("Form Değerleri:", values);
  };
  return (
   <>
      <div style={{width:'95%'}}>
        <Title level={5} className="profile-msg-title">
          Hesap Ayarları Formu
        </Title>
        <Divider />
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            emailUpdates: true,
            joinDietClub: false,
          }}
        className="setting-from"
        >
          {/* Üye Adı */}
          <Form.Item
            label="Üye Adı"
            name="memberName"
            rules={[{ required: true, message: "Lütfen üye adınızı giriniz!" }]}
          >
            <Input placeholder="Üye Adı" />
          </Form.Item>

          {/* E-posta */}
          <Form.Item
            label="E-posta"
            name="email"
            rules={[
              { required: true, message: "Lütfen e-posta adresinizi giriniz!" },
              { type: "email", message: "Geçerli bir e-posta adresi giriniz!" },
            ]}
          >
            <Input placeholder="E-posta" />
          </Form.Item>

          {/* Doğum Tarihi */}
          <Form.Item label="Doğum Tarihi" name="birthDate">
            <DatePicker style={{ width: "100%" }} onChange={() => setShowBirthYearCheckbox(true)} />
          </Form.Item>

          {showBirthYearCheckbox && (
            <Form.Item name="showBirthYear" valuePropName="checked">
              <Checkbox>
                Herkesin yaşınızı görmesini istiyorsanız işaretleyiniz.
              </Checkbox>
            </Form.Item>
          )}

          {/* Meslek */}
          <Form.Item label="Meslek" name="profession">
            <Input placeholder="Meslek" />
          </Form.Item>

          {/* Eğitim Durumu */}
          <Form.Item label="Eğitim Durumunuz" name="education">
            <Select placeholder="Eğitim Durumunuzu Seçiniz">
              <Option value="lise">Lise</Option>
              <Option value="lisans">Lisans</Option>
              <Option value="yükseklisans">Yüksek Lisans</Option>
              <Option value="doktor">Doktora</Option>
            </Select>
          </Form.Item>

          {/* Yaşadığınız Yer */}
          <Form.Item label="Yaşadığınız Yer" name="location">
            <Input placeholder="Yaşadığınız Yer" />
          </Form.Item>

          {/* Kulüp ve Abonelik Seçenekleri */}
          <Form.Item name="membershipOptions" label="Kulüpler">
            <Checkbox.Group>
              <Row>
                <Col span={24}>
                  <Checkbox value="motherClub">Anneler Kulübüne Katılmak İstiyorum</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="joinDietClub"
                    onChange={(e) => setJoinDietClub(e.target.checked)}
                  >
                    Diyet Kulübüne Katılmak İstiyorum
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>

          {/* Diyet Bilgileri */}
          {joinDietClub && (
            <>
              <Form.Item label="Boy (cm)" name="height">
                <Input placeholder="Boyunuzu giriniz" />
              </Form.Item>
              <Form.Item label="Kilo (kg)" name="weight">
                <Input placeholder="Kilonuzu giriniz" />
              </Form.Item>
            </>
          )}

          {/* Gönder Butonu */}
          <Form.Item style={{marginTop:'20px', textAlign:'center'}}>
            <Button type="primary" htmlType="submit" block style={{width:'200px'}}>
              Formu Kaydet
            </Button>
          </Form.Item>
        </Form>
      </div>
   </>
  );
};

export default AccountSettings;
