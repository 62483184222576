/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

interface UserState {
  userIp: any;
  userData: string;
  navShow: Boolean;
  profile:any;
}

const initialState = {
  userIp: {},
  userData: '',
  navShow: false,
  profile:[]
} as UserState;

const userSlice = createSlice({ 
  name: 'user',
  initialState,
  reducers: {
    setUserIp(state, action) {
      state.userIp = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setNavShow(state, action) {
      state.navShow = action.payload;
    },
    setProfiles(state, action) {
      state.profile = action.payload;
    }
  },
});

export const { setUserIp , setNavShow, setUserData,setProfiles} = userSlice.actions;
export default userSlice.reducer;
