import * as React from "react";
import { createContext, ReactNode, useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { fakeAuthProvider, urls } from "./auth";
import { LocalStorageService } from '../helpers/LocalStorage';

import HomePage from "../pages/Home/HomePage";
import Login from "../pages/Login"
import SignUp from "../pages/SignUp";
import Comfirm from "../pages/Confirm";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import SearcResult from "../pages/SearcResult";

import TopicForm from "../pages/Form/TopicForm";
import CategorieDetails from "../pages/Form/CategorieDetails";
import TopicList from "../pages/Form/TopicList";
import Topic from "../pages/Form/Topic";
import TrendingTopics from "../pages/Form/TrendingTopics";
import NewlyAddedTopics from "../pages/Form/NewlyAddedTopics";
import NewUserMessages from "../pages/Form/NewUserMessages";
import MattersPendingApproval from "../pages/Form/MattersPendingApproval";
import DetailPendingApproval from "../pages/Form/DetailPendingApproval";
import Deneme from "../pages/Form/Deneme";

import Profile from "../pages/Member/Profile";
import Settings from "../pages/Member/Settings";

import DietClub from "../pages/DietClub/DietClub";

import BlogHome from "../pages/Blog/BlogHome";

const AppRouter = () => {
 
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" Component={HomePage} />
        <Route path="/giris" Component={Login} />
        <Route path="/kayit-ol" Component={SignUp} />
        <Route path='/users/confirm/:token' Component={Comfirm} />
        <Route path="/sifremi-unuttum" Component={ForgotPassword} />
        <Route path="/users/sifremi-guncelle/:token" Component={ResetPassword} />
        <Route path='/arama-sonuclari/:kelime' Component={SearcResult} />

        {/* <Route path='/yeni-konu' Component={TopicForm} /> */}
        <Route path="/yeni-konu" element={
          <RequireAuth>
            <TopicForm />
          </RequireAuth>
        } />

        <Route path='/:categorieLink' Component={CategorieDetails} />
        <Route path='/deneme' Component={Deneme} />
        <Route path='/topics/:urlName' Component={TopicList} />
        <Route path='/:topCategory/:category' Component={Topic} />
        <Route path='/trend-konular' Component={TrendingTopics} />
        <Route path='/yeni-eklenen-konular' Component={NewlyAddedTopics} />
        <Route path='/yeni-kullanici-mesajlari' Component={NewUserMessages} />

        {/* <Route path='/onay-bekleyen-konular' Component={MattersPendingApproval} /> */}

        <Route path="/onay-bekleyen-konular" element={
          <RequireRole>
            <MattersPendingApproval />
          </RequireRole>
        } />

        
        <Route path="/onay-bekleyen-konu-detay" element={
          <RequireRole>
            <DetailPendingApproval />
          </RequireRole>
        } />

        {/* <Route path='/profilim/:page' Component={Profile} /> */}
        <Route path="/profilim/:page" element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        } />

        <Route path="/ayarlar" element={
          <RequireAuth>
            <Settings />
          </RequireAuth>
        } />

        {/* <Route path='/ayarlar' Component={Settings} /> */}

        <Route path='/diyet-kulubu' Component={DietClub} />

        <Route path='/blog' Component={BlogHome} />

      </Routes>
    </AuthProvider>

  );
};

interface AuthContextType {
  token: any;
  signin: (token: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

const AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: ReactNode }) {
  const [token, setToken] = useState<any>(null);

  useEffect(() => {
    const token = LocalStorageService.getAuthToken();
    token && LocalStorageService.setAuthToken(token);
  }, []);

  const signin = (newToken: string, callback: VoidFunction) => {
    return fakeAuthProvider.signin(() => {
      setToken(newToken);
      callback();
    });
  };

  const signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      setToken(null);
      callback();
    });
  };

  const value = { token, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const token = LocalStorageService.getAuthToken();

  if (!auth.token && !token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else if (location.pathname === "/") {
    return (
      // <Navigate to={urls.uploadInvoice} state={{ from: location }} replace />
      <Navigate to={urls.homePage} state={{ from: location }} replace />
    );
  }

  return children;
}

function RequireRole({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const token = LocalStorageService.getAuthToken();
  const role = LocalStorageService.getRole();

  if (!token || token && role !== 'Administrator') {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else if (location.pathname === "/") {
    return (
      // <Navigate to={urls.uploadInvoice} state={{ from: location }} replace />
      <Navigate to={urls.homePage} state={{ from: location }} replace />
    );
  }

  return children;
}




export default AppRouter;
