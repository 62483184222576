import React  from 'react';
import { Input, Button, notification, Form, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import { MailOutlined } from '@ant-design/icons';
import { forgotPasswordApi } from '../services/userAuth';

const ForgotPassword = () => {
  const [form] = Form.useForm();
  let navigate = useNavigate();

  const handleSend = async (values:any) => {  
    console.log('şifremi unur')
    try{
      const response:any = await forgotPasswordApi(values);
      console.log('res',response.status)
      if(response.status === 204){
        form.resetFields(); 
        notification.warning({
          message: `Lütfen mailinizi kontrol ediniz.`,
        });
        navigate("/giris");
      }
    }catch(e:any){
      console.log(e)
    }
  };

    return (
        <>
        <Row style={{justifyContent:'center'}}>
          <Col xs={24} md={12} lg={12} >
          <div className='blog-card' >
              <div className='cardx' style={{padding:'40px'}}>
                <article className='article' style={{justifyContent:'center'}}>
                  <div className='right-txtt'>
                    <h2 style={{textAlign:'center'}}>Şifremi Unuttum</h2>
                    <Form
                      form={form}
                      name="emailForm"
                      onFinish={handleSend} 
                      layout="vertical"
                      style={{maxWidth: 600, minWidth:300}}
                    >
                      <Form.Item
                        name="email"
                        label="E-posta Adresi"
                        rules={[
                          { 
                            required: true, 
                            message: 'Lütfen e-posta adresinizi girin!' 
                          },
                          { 
                            type: 'email', 
                            message: 'Lütfen geçerli bir e-posta adresi girin!' 
                          }
                        ]}
                      >
                        <Input
                          prefix={<MailOutlined />}
                          placeholder="E-posta adresi girin"
                        />
                      </Form.Item>
                      
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit" 
                        >
                          Gönder
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </article>
              </div>
            </div>
        
          </Col>
        </Row>
        </>
    )
}

export default ForgotPassword; 