import React, {useEffect, useState} from 'react';
import { Card, Row, Col,Divider, Button,Modal,Tooltip,Avatar  } from 'antd';
import Slider from '../../components/Slider';
import deneme from '../../assets/deneme.jpg'
import BlogTabContent from './BlogTabContent'

const { Meta } = Card;

const BlogHome = () => {

  const editors = [
    {
      name: "Prof.DR. Elif Yılmaz",
      avatar: "https://via.placeholder.com/100",
      title: "Tüp Bebek Uzmanı",
      bio: "Edebiyat ve psikoloji üzerine yoğunlaşan bir editör. Okuyuculara ilham veren hikayeler seçmeyi seviyor.",
      recentBlogs: ["Küçük Ağac’ın Eğitimi", "Hayata Dönüş", "İnsanı Anlama Yolları"],
    },
    {
      name: "Ahmet Demir",
      avatar: "https://via.placeholder.com/100",
      title: "Kitap Eleştirmeni",
      bio: "Toplumsal ve kültürel konulara odaklanan bir editör. Derinlemesine analizleriyle tanınır.",
      recentBlogs: ["Bir Toplumun İnşası", "Adalet Arayışı", "Doğanın Şifası"],
    },
  ];
  

  return (
  <>
    <Row>
      <Col md={16} lg={16} sm={24} style={{borderRight:'1px solid #d9d9d9', borderBottom:'1px solid #d9d9d9'}} >
        <Slider/>
      </Col>
      <Col md={8} lg={8} sm={24} >
      <div className="editor-section" style={{marginTop:'15px', marginLeft:'10px'}}>
        {editors.map((editor, index) => (
          <Card
            key={index}
            className="editor-card"
            style={{
              width: "100%",
              marginBottom: "16px",
              // marginLeft:'10px'
            }}
          >
            <Meta
              avatar={<Avatar src={deneme} size={64} />}
              title={
                <div>
                  <h4 style={{ margin: 0 }}>{editor.name}</h4>
                  <small style={{ color: "#888" }}>{editor.title}</small>
                </div>
              }
              description={
                <div>
                  {/* <p>{editor.bio}</p> */}
                  <p>
                    <strong>Son Yazıları:</strong>
                  </p>
                  <ul>
                    {editor.recentBlogs.map((blog, i) => (
                      <li key={i}>
                      <a>{blog}</a>  
                      </li>
                    ))}
                  </ul>
                </div>
              }
            />
          </Card>
        ))}
      </div>
      </Col>
    </Row> 
    <Row className='other-blog-row'>
      <Col md={24} lg={24} sm={24}>
        <BlogTabContent/>
      </Col>
    </Row> 
  </>     
  );
};

export default BlogHome;
