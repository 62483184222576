
import {api} from '../services/apis';
import {apiLookUp} from '../services/apis';
import {LocalStorageService} from '../helpers/LocalStorage';

const headers = {
  headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }
}

const getIpApi = async ()  => {
//  return  await fetch('https://api.db-ip.com/v2/free/self')
return  await fetch('https://ipinfo.io/?token=89301fee5fc8b5')
     .then((response) => {
       if (!response.ok) {
         throw new Error(`Request failed with status code: ${response.status}`);
       }
       return response.json();
     })
    
     .catch((error) => {
      console.log(error)
      //  setError(error.message);
     });
};

const UserVisitor = async (data: any): Promise<Object> => {
  return (
    await api.post(`visitors`, data)
  );
};

const createUserApi = async (data: any): Promise<Object> => {
  return (
    await api.post(`users`, data, headers
       )
  );
};

const confirmApi = async (token: any): Promise<Object> => {
  return (
    await api.post(`users/confirm-email`, {token}, headers )
  );
};

const loginApi = async (data: any): Promise<Object> => {
  return (
    await api.post(`users/authenticate`, data,  headers )
  );
};

const forgotPasswordApi = async (email: any): Promise<Object> => {
  return (
    await api.post(`users/reset-forgotten-password`, email, headers
       )
  );
};

const resetPasswordApi = async (data: any): Promise<Object> => {
  return (
    await api.patch(`users/reset-forgotten-password`, data,  headers )
  );
};

const getProfessionsApi = async (): Promise<Object> => {
  return await apiLookUp.get(`professions`, headers
  );
};

const getEducationApi = async (): Promise<Object> => {
  return await apiLookUp.get(`education-statuses`, headers
  );
}

const getCitiesApi = async (): Promise<Object> => {
  return await apiLookUp.get(`cities`, headers
  );
}

const getCaptchaQuestionApi = async (): Promise<Object> => {
  return await apiLookUp.get(`captcha-question`, headers
  );
}




export {
    loginApi,
    UserVisitor,
    getIpApi,
    createUserApi,
    confirmApi,
    forgotPasswordApi,
    resetPasswordApi,
    getProfessionsApi,
    getEducationApi,
    getCitiesApi,
    getCaptchaQuestionApi
}