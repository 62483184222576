import React from "react";
import { Carousel } from "antd";

const Slider = () => {
  const items = [
    {
      title: "Küçük Ağac’ın Eğitimi: İnsanlığın Doğaya ve Sevgiye Dönüş Hikayesi",
      description:
        "Toplumsal adaletsizliklerin arttığı, insan ilişkilerinin zedelendiği, insanlığa ve iyiliğe olan umudun azaldığı bu günlerde kalbimizi ısıtacak, umut tohumlarını yeşertecek bir kitap önermek istedik.",
      image: 'https://kikwell.com/wp-content/uploads/2025/01/subat-2025-aylik-burc-yorumlari-600x400.webp'
    },
    {
      title: "Başka Bir Kitap Önerisi",
      description:
        "Bu kitap, insanlığın doğayla bağını ve sevgiye olan özlemini yansıtıyor. Bu hikaye, okuyanları derinden etkiliyor.",
      image: 'https://kikwell.com/wp-content/uploads/2025/01/subat-2025-aylik-burc-yorumlari-600x400.webp',
    },
  ];

  return (
    <div className="slider-container" style={{padding:'15px'}}>
      <Carousel autoplay dots className="custom-carousel">
        {items.map((item, index) => (
          <div key={index} className="carousel-item">
            <div className="carousel-content">
              <div className="text-content">
                <h2>{item.title}</h2>
                <p>{item.description}</p>
                <a href="#more" className="read-more">
                  Devamını Oku →
                </a>
              </div>
              <div className="image-content">
                <img src={item.image} alt={item.title} />
              </div>
            </div>
          </div>
        ))}
      </Carousel>
   
    </div>
  );
};

export default Slider;
