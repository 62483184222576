import React,{useState} from "react";
import { Form, Input, Button, Typography, Divider,notification } from "antd";
import {validatePassword} from '../../helpers/validators';
import {changePasswordApi} from '../../services/contents';

const { Title } = Typography;

const SecuritySettings = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values:any) => {
    setLoading(true);
    try{
      const response:any = await changePasswordApi(values);
      if(response.status === 200){
        notification.success({
          message: `Şifreniz başarılı güncellenmiştir.`,
        });
        form.resetFields();
        setLoading(false);
      }
    }catch(e:any){
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <div style={{width:'95%'}}>
      <Title level={5} className="profile-msg-title">
        Şifre Güncelle
      </Title>
      <Divider />

      <Form
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          twoFactorAuth: false,
          activityNotifications: true,
        }}
      className="setting-from"
      >

        <Form.Item
          label="Eski Şifre"
          name="currentPassword"
          rules={[{ required: true, message: "Lütfen eski şifrenizi giriniz!" }]}
        >
          <Input.Password placeholder="Eski Şifrenizi Giriniz" />
        </Form.Item>

        <Form.Item
          label="Yeni Şifre"
          name="newPassword"
          rules={[
            {
              required: true,
              validator: (_, value) => validatePassword(value)
            },
          ]}
        >
          <Input.Password placeholder="Yeni Şifrenizi Giriniz" />
        </Form.Item>

        <Form.Item
          label="Yeni Şifre Tekrar"
          name="confirmNewPassword"
          dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Lütfen yeni şifrenizi tekrar giriniz!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Şifreler eşleşmiyor!"));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Yeni Şifrenizi Tekrar Giriniz" />
        </Form.Item>

        {/* Kaydet Butonu */}
        <Form.Item style={{marginTop:'30px', textAlign:'center'}}>
          <Button type="primary" htmlType="submit" block loading={loading} style={{width:'200px'}}>
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SecuritySettings;
