import React, { useEffect, useState } from "react";
import moment from 'moment';
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate } from "react-router-dom";
import {setSearchPageSize,setSearchCurrentPage} from '../reducers/topicForm';
import BlogBreadcrumb from "../components/BlogBreadcrumb";
import { Pagination, Avatar } from "antd";


const SearcResult = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // const [currentPage, setCurrentPage] = useState<any>(1);
  // const [pageSize, setPageSize] = useState<any>(10);
  // const [totalDataCount, setTotalDataCount] = useState(1)

  const {searchResult, searchPageSize, searchCurrentPage, searchTotalDataCount} = useSelector((state: any) => state.topicForm);

  const  breadcrumbItems = [
    { name: 'Anasayfa', url: '/' },
    { name: 'Arama Sonuçları', url: '/arama-sonucları' },
  ];

const handlePageChange = (page:any,size:any) => {
      dispatch(setSearchCurrentPage(page));
      dispatch(setSearchPageSize(size));
    };

useEffect(() => {
  const timeout = setTimeout(() => {
    if (searchResult === null  ) {
      navigate(`/`);
    }
  }, 4000); 
  return () => clearTimeout(timeout); 
}, [searchResult, navigate]);

  return (
    <>
     <BlogBreadcrumb breadcrumbItems={breadcrumbItems}  />
     <div className="topics-page ">
       <div className="topic-page-title" style={{marginBottom:'20px'}}>
        <p>
          Aradığınız konu ile ilgili konular aşağıda listelenmiştir.
        </p>
      </div>
      <div style={{ textAlign: "left", marginTop: "20px" }}>
        <Pagination
         current={searchCurrentPage}
         pageSize={searchPageSize}
         total={searchTotalDataCount}
         onChange={handlePageChange}
         showSizeChanger={true}
         pageSizeOptions={['10', '20', '50', '100']} 
        style={{marginBottom:'20px'}} 
        />
      </div>
      <div className="topic-list">
        {
        searchResult === null ? <p style={{padding:'50px 0'}}>Arama sonucu bulunamadı.</p>
         :
         searchResult && searchResult.map((result:any, index:any) => (
          <div key={index} className="box-card box-card-div box-card-article" >
            <div className="topic-info topic-search">
              
                {
                  result.userPhotoLink !== null ? 
                  <Avatar size={40} src={`https://api.kadincagundem.com/images/${result.userPhotoLink}`} className="avatar" />
                    :
                  <Avatar size={40} icon={result.username.slice(0, 1).toUpperCase()} />
                }
                <div className="topic-details">
                  <h3><a href={`/topics/${result.subcategorySeoLink}`} >{result.topicTitle}</a></h3>
                  <p>{moment(result.contentCreatedDate).format('DD-MM-YYYY')}</p>
                  <div dangerouslySetInnerHTML={{ __html: result.content.replace(/<p><br><\/p>/g, "") }} />
                </div>
            </div>
          </div>
        ))}
      </div>
     
    </div>
    </>
   
  );
};

export default SearcResult;