import React,{useEffect, useState} from 'react';
import { List, Avatar, Typography, Badge, Button,Divider,Pagination , notification } from 'antd';
import moment from 'moment';
import { ClockCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import {getNotificationsApi, notificationsTriggerApi,deleteNotificationApi} from '../../services/profile';

const { Title, Text } = Typography;

const NotificationsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [notifications, setNotifications] = useState<any>([]);
  const [totalDataCount, setTotalDataCount] = useState()

  const getNotifications = async () => {
    try{
      const response:any = await getNotificationsApi(currentPage,pageSize);
      if(response.status === 200){
        setNotifications(response?.data?.notifications);
        setTotalDataCount(response?.data?.totalDataCount);
      }
    }catch(e:any){
      console.log(e)
    }
  }

  const notificationsDetail = async (notificationId:string) => {
    try{
      const response:any = await notificationsTriggerApi(notificationId);
      if(response.status === 202){
        getNotifications();
      }
     
    }catch(e:any){
      console.log(e)
    }
  }

  const handlePageChange = (page:any,size:any) => {
    setCurrentPage(page);
    setPageSize(size);
  };

const deleteNotification = async (notificationId:any) => {
  try{
    const response:any = await deleteNotificationApi(`notification-ids=${notificationId}`);
    if(response.status === 204){
      notification.success({
        message: `Başarıyla silindi.`,
      });
      getNotifications();
    }
  } catch(e:any){
    console.log(e)
  }
}

const deleteNotificationAll = async () => {
  const notificationIdAll =  notifications.map((item:any) => `notification-ids=${item.notificationId}`).join("&");
  try{
    const response:any = await deleteNotificationApi(notificationIdAll);
    if(response.status === 204){
      notification.success({
        message: `Başarıyla silindi.`,
      });
      getNotifications();
    }
  } catch(e:any){
    console.log(e)
  }
}

  useEffect(() => {
    getNotifications();
  },[pageSize, currentPage])
  
  return (
    <div title="Bildirimler" className='profile-div'>
      <Button type="default" className='profile-top-btn' onClick={deleteNotificationAll} >
          <DeleteOutlined/> Tüm Bildirimleri Sil
      </Button>
      <Divider/>
      {
        notifications !== null ?
        <List
        itemLayout="horizontal"
        className='notifications-list'
        dataSource={notifications }
        style={{minHeight:'400px'}}
        renderItem={(item:any) => (
          <List.Item
            actions={[
              <Text type="secondary">
                <ClockCircleOutlined /> {moment(item.notificationDate).format('DD-MM-YYYY')}
              </Text>,
              <span style={{cursor:'pointer'}} onClick={() => deleteNotification(item.notificationId)}><DeleteOutlined/></span>
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar />}
              title={
                <Title level={5} style={{ marginBottom: 0, cursor:'pointer' }} onClick={() => notificationsDetail(item.notificationId)}>
                  <Badge
                    dot={!item.isRead}
                    style={{
                      marginRight: 10,
                    }}
                  />
                  {item.notificationContent}
                </Title>
              }
              // description={<Text>{item.content}</Text>}
            />
          </List.Item>
        )}
      /> :
      <div  style={{minHeight:'400px'}}>
        <p>Bildirim bulunmamaktadır.</p>
      </div>
      
      }
     
      <div style={{ textAlign: "left", marginTop: "20px" }}>
        <Pagination
          className='ant-pagination-bottom'
          current={currentPage}
          pageSize={pageSize}
          total={totalDataCount}
          onChange={handlePageChange}
          showSizeChanger={true}
          pageSizeOptions={['10', '20', '50', '100']} 
        />
      </div>
    </div>
  );
};

export default NotificationsPage;