import React from 'react';
import { Tabs } from 'antd';


const { TabPane } = Tabs;

const BlogTabContentTask = ({ blogs }:any) => (
  <div className="grid grid-cols-3 gap-4 blog-tab-content" >
    {blogs.map((blog:any, index:any) => (
      <div
        key={index} 
        style={{display:'flex'}}
      >
        <img
          width={90}
          height={90}
          src={blog.image}
          alt={blog.title}
          className="rounded-lg w-full h-40 object-cover mb-4"
        />
        <div style={{paddingLeft:'8px'}}>
          <h5 style={{margin:'0', color:' #983961'}}>{blog.title}</h5>
          <p className="text-gray-600 text-sm">{blog.description}</p>
        </div>
      </div>
    ))}
  </div>
);

const BlogTabContent = () => {

  const categories = [
    {
      title: "Psikoloji",
      blogs: [
        {
          title: "Düşüncelerinizi Şekillendirin: Olumsuz Düşünce Döngüsünü Kırmak",
          description: "Olumsuz düşünceleri nasıl durdurabilirsiniz? İşte yolları!",
          image: "https://via.placeholder.com/150"
        },
        {
          title: "Mutlulukla İlgili Gerçekler: Araştırmalar Ne Söylüyor?",
          description: "Mutlu olmanın bilimsel yollarını öğrenin.",
          image: "https://via.placeholder.com/150"
        },
        {
          title: "Kapanmayan Yetersizlik Yarası: Narsist Bir Anne ile Büyümek",
          description: "Aile travmaları ile nasıl başa çıkılır?",
          image: "https://via.placeholder.com/150"
        },
        {
          title: "Düşüncelerinizi Şekillendirin: Olumsuz Düşünce Döngüsünü Kırmak",
          description: "Olumsuz düşünceleri nasıl durdurabilirsiniz? İşte yolları!",
          image: "https://via.placeholder.com/150"
        },
        {
          title: "Mutlulukla İlgili Gerçekler: Araştırmalar Ne Söylüyor?",
          description: "Mutlu olmanın bilimsel yollarını öğrenin.",
          image: "https://via.placeholder.com/150"
        },
        {
          title: "Kapanmayan Yetersizlik Yarası: Narsist Bir Anne ile Büyümek",
          description: "Aile travmaları ile nasıl başa çıkılır?",
          image: "https://via.placeholder.com/150"
        }
      ]
    },
    {
      title: "İlişkiler",
      blogs: [
        {
          title: "İlişkide İlginin Azalması: 'Fizzing' Sürecine Girmiş Olabilir misiniz?",
          description: "İlişkilerde ilgi kaybını anlamanın yolları.",
          image: "https://via.placeholder.com/150"
        },
        {
          title: "Döngüyü Kırmanın Yolu: İlişkilerde Büyümek",
          description: "Sağlıklı ilişkiler için ipuçları!",
          image: "https://via.placeholder.com/150"
        },
        {
          title: "İlişkilerde 3-6-9 Kuralı: Bu Aşkın Bir Geleceği Var mı?",
          description: "İlişkilerdeki kuralın detaylarını öğrenin.",
          image: "https://via.placeholder.com/150"
        }
      ]
    },
    {
      title: "Sağlık ve Güzellik",
      blogs: [
        {
          title: "Doğanın Sessiz Şifacıları: Çiçeklerin İyileştirici Gücü",
          description: "Çiçeklerin zihinsel sağlığa katkıları.",
          image: "https://via.placeholder.com/150"
        },
        {
          title: "Psikolojik Faktörlerin Kilo Verme Sürecine Etkisi",
          description: "Psikolojiniz kilo vermenizi etkileyebilir mi?",
          image: "https://via.placeholder.com/150"
        },
        {
          title: "İçten Dışa Güzellik: Gerçek Işıltınızı Ortaya Çıkarmanın Sırları",
          description: "Gerçek güzelliğinizi keşfedin!",
          image: "https://via.placeholder.com/150"
        }
      ]
    }
  ];

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
    <h4 style={{marginTop:'0'}}>Son Eklenen Yazılar</h4>
    <Tabs defaultActiveKey="0" type="card">
      {categories.map((category, index) => (
        <TabPane tab={category.title} key={index}>
          <BlogTabContentTask blogs={category.blogs} />
        </TabPane>
      ))}
    </Tabs>
  </div>    
  );
};

export default BlogTabContent;
