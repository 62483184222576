import React, { useState } from "react";
import { Button, Col, Form, Input, Row, notification } from 'antd';
import {useLocation,  useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { resetPasswordApi } from '../services/userAuth'

const ResetPassword = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const confirmUrl = location.pathname.split('/');
    const token = confirmUrl[3];
    const [isLoading, setIsLoading] = useState(false);
  
    const onLogin = async (values:any) => {
        setIsLoading(true);
        if(values.newPassword !== values.confirmNewPassword){
            notification.warning({
                message: `Şifre ve şifre tekrarınız aynı değildir.`,
              });
        }else{
            const data = {
                token:token,
                newPassword:values.newPassword,
                confirmNewPassword:values.confirmNewPassword
            }
            try{
                const response:any = await resetPasswordApi(data);
                if(response.status === 204 ){
                    setIsLoading(false);
                    notification.warning({
                        message: `Şifreniz başarıyla güncellenmiştir.`,
                      });
                    navigate("/giris");
                }
    
            }catch(e:any){
                console.log(e)
            }
        } 
    }
   
 
    return (
        <>
            <Row justify="center">
                <Col xs={24} md={12} lg={12} style={{ textAlign: 'center' }}>
                    <div className='blog-card' >
                        <div className='cardx' style={{ padding: '20px' }}>
                            <article className='article'>
                                <div className='right-txtt'>
                                    <h2 style={{ textAlign: 'center' }}>Şifremi Güncelle</h2>
                                    <div className="signup-form">
                                        <Form name="login" onFinish={onLogin}  layout="vertical" >
                                            <Form.Item
                                                label='Yeni Şifre'
                                                className="email-input"
                                                name="newPassword"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Lütfen kullanıcı adınızı giriniz!',
                                                    },
                                                ]}
                                            >
                                                 <Input.Password
                                                    type={"password"}
                                                    placeholder="Şifre"
                                                    iconRender={(visible: any) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label="Yeni Şifre Tekrar"
                                                name="confirmNewPassword"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Lütfen şifrenizi giriniz!',
                                                    },
                                                ]}
                                            >
                                                <Input.Password
                                                    type={"password"}
                                                    placeholder="Şifre"
                                                    iconRender={(visible: any) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                />
                                            </Form.Item>
                                            <Form.Item className="m-0"   style={{ marginTop: '20px' }}>
                                                <Button
                                                    className="login-btn"
                                                    type="primary"
                                                    htmlType="submit"
                                                    size="large"
                                                    loading={isLoading}
                                                    block
                                                >
                                                   Şifre Kaydet
                                                </Button>
                                            </Form.Item>

                                    
                                        </Form>

                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ResetPassword; 