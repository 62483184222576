import React, { useState } from "react";
import {  Table, Typography, Divider } from "antd";

const { Title } = Typography;

const TopicsFollow = () => {
    // Açılan konular
    const [topics, setTopics] = useState([
      { id: 1, title: "React ile Profil Sayfası Tasarımı", subjectOwner:'Nazliy' ,date: "2024-11-01", comments: 12 },
      { id: 2, title: "JavaScript'te Fonksiyonel Programlama", subjectOwner:'Nazliy', date: "2024-11-05", comments: 8 },
      { id: 3, title: "Ant Design Kullanım İpuçları",subjectOwner:'Nazliy', date: "2024-11-10", comments: 15 },
      { id: 4, title: "Frontend Geliştirme için En İyi Uygulamalar", subjectOwner:'Nazliy', date: "2024-11-15", comments: 20 },
      { id: 5, title: "Yazılım Mühendisliğinde Kariyer Yönetimi",subjectOwner:'Nazliy', date: "2024-11-20", comments: 7 },
      { id: 6, title: "Web Performans Optimizasyonu",subjectOwner:'Nazliy', date: "2024-11-25", comments: 14 },
      { id: 7, title: "Yeni Başlayanlar için TypeScript",subjectOwner:'Nazliy', date: "2024-12-01", comments: 9 },
      { id: 8, title: "CSS Grid ve Flexbox Rehberi",subjectOwner:'Nazliy', date: "2024-12-05", comments: 5 },
      { id: 9, title: "Web Tasarımında Renk Teorisi",subjectOwner:'Nazliy', date: "2024-12-08", comments: 4 },
      { id: 10, title: "Modern API'lar ile Backend Geliştirme",subjectOwner:'Nazliy', date: "2024-12-10", comments: 18 },
    ]);

     // Tablo kolonları
  const columns:any = [
    {
      title: "Konu Başlığı",
      dataIndex: "title",
      key: "title",
      render: (text:any) => <a>{text}</a>, // Başlıklar tıklanabilir
    },
    {
      title: "Açılma Tarihi",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Konuyu Sahibi",
      dataIndex: "subjectOwner",
      key: "subjectOwner",
    },
    {
      title: "Yorum Sayısı",
      dataIndex: "comments",
      key: "comments",
      align: "center",
    },
  ];

  return (
   <>
     <div>
          <Title level={5} className="profile-msg-title">Takip Ettiğim Konular</Title>
          <Divider/>
          <Table
            columns={columns}
            dataSource={topics}
            rowKey="id"
            pagination={{
              pageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "15"],
              showTotal: (total) => `Toplam ${total} konu`,
            }}
          />
        </div>
   </>
  );
};

export default TopicsFollow;
