import React from 'react';
import { Avatar, Card, Badge, Space, Typography } from 'antd';
import { MessageOutlined, LikeOutlined, CalendarOutlined } from '@ant-design/icons';

const { Text } = Typography;

const TopicUserCard = ({userName, status,mesaj,like,date,badgeSaveUser,badgeMomClub,badgeDietClub, userRole, image}:any) => {
    let location = -30 ;

  return (
    <Card className="card-box">
      {/* Avatar ve Kullanıcı Adı */}
      <Space direction="vertical" className="topic-user-avatar-space">
        {
           image !== 'null' ? 
            <Avatar 
              className={`topic-user-avatar ${status ? "online" : "offline"}`}
              src={`https://api.kadincagundem.com/images/${image}`} />
              :
           <Avatar 
            className={`topic-user-avatar ${status ? "online" : "offline"}`}
            icon={userName.slice(0, 1).toUpperCase()} />
        }

        <div className="card-p-mobil">
          <div>
            <Text strong style={{ fontSize: "16px" }}>
              {userName}
            </Text>
            <Text>
              {status ? (
                <span style={{ color: "#52c41a" }}> Aktif</span>
              ) : (
                <span style={{ color: "#ff0000" }}> Pasif</span>
              )}
            </Text>
          </div>
          <Space className="user-information mobil-user-information" direction="vertical" size="small">
            <Space>
              <CalendarOutlined />
              <Text className="t-u-span">{date || "-"}</Text>
            </Space>
            <Space>
              <MessageOutlined />
              <Text className="t-u-span">{mesaj || "0"}</Text>
            </Space>
            <Space>
              <LikeOutlined />
              <Text className="t-u-span">{like || "0"}</Text>
            </Space>
          </Space>
        </div>
      </Space>

      {/* Üye Bilgileri */}
      <Space className="user-information" direction="vertical" size="small">
        <Space>
          <CalendarOutlined />
          <Text className="t-u-span">{date || "-"}</Text>
        </Space>
        <Space>
          <MessageOutlined />
          <Text className="t-u-span">{mesaj || "0"}</Text>
        </Space>
        <Space>
          <LikeOutlined />
          <Text className="t-u-span">{like || "0"}</Text>
        </Space>
      </Space>

      {/* Rozetler */}
      <div className="skor-profil">
        {userRole && (
          <Badge.Ribbon text="Yönetici" color="blue" style={{ top: location }} />
        )}
        {userRole !== "Administrator" ? badgeSaveUser && (
          <Badge.Ribbon text="Kayıtlı Üye" color="gold" style={{ top: location }} />
        ):''}
        {badgeDietClub && (
          <Badge.Ribbon text="Diyet Kulübü" color="red" style={{ top: location + 30 }} />
        )}
         {badgeMomClub && (
          <Badge.Ribbon text="Anneler Kulübü" color="pink" style={{ top: location + 60 }} />
        )}
      </div>
    </Card>
    // <Card className='card-box'>
    //   {/* Avatar ve Kullanıcı Adı */}
    //       <Space direction="vertical" className='topic-user-avatar-space'>
    //           <Avatar className={`topic-user-avatar `} 
    //               icon={<UserOutlined />} style={{ backgroundColor: '#C4C4A3' }}
    //            />
    //            <div className='card-p-mobil'>
    //             <div>
    //                 <Text strong style={{ fontSize: '16px' }}>{user.userName}</Text>
    //                 <Text>Üye /
    //                 {/* {
    //                     userData[0]?.statu ?  <span style={{color:'#52c41a'}}> Aktif</span> : <span style={{color:'#ff0000'}}> Pasif</span>
    //                 } */}
    //                 </Text>
    //             </div>
    //                 <Space className='user-information mobil-user-information' direction="vertical" size="small">
    //                     <Space>
    //                         <CalendarOutlined />
    //                         <Text className='t-u-span'>{user.date}</Text>
    //                     </Space>
    //                     <Space>
    //                         <MessageOutlined />
    //                         <Text className='t-u-span'>{user.mesaj}</Text>
    //                     </Space>
    //                     <Space>
    //                         <LikeOutlined />
    //                         <Text className='t-u-span'>{user.like}</Text>
    //                     </Space>
    //                 </Space>
    //            </div>
    //       </Space>
    //       {/* Üye Bilgileri */}
    //       <Space className='user-information' direction="vertical" size="small">
    //           <Space>
    //               <CalendarOutlined />
    //               <Text className='t-u-span'>{user.date}</Text>
    //           </Space>
    //           <Space>
    //               <MessageOutlined />
    //               <Text className='t-u-span'>{user.mesaj}</Text>
    //           </Space>
    //           <Space>
    //               <LikeOutlined />
    //               <Text className='t-u-span'>{user.like}</Text>
    //           </Space>
    //       </Space>
    //       <div className='skor-profil'>
    //       {
    //             user.badgeSaveUser ? (
    //                 <Badge.Ribbon text="Kayıtlı Üye" color="gold" style={{ top: location }} />
    //             ): ''
    //         }
    //         {
    //             user.badgeMomClub ? (
    //                 <Badge.Ribbon text="Annneler Kulubü" color="pink" style={{ top: location + 30 }} />
    //             ): ''
    //         }
    //         {
    //             user.badgeDietClub ? (
    //                 <Badge.Ribbon text="Diyet Kulubü" color="red" style={{ top: location + 60 }} />
    //             ): ''
    //         }
    //       </div>
         
    // </Card>
  );
};

export default TopicUserCard;
