import React, {useEffect, useState} from 'react';
import { Card, Row, Col,Divider, Button,Modal,Tooltip } from 'antd';
import MeasurementForm from '../../components/MeasurementForm';
import MeasurementNotebook from '../../components/MeasurementNotebook';
import CalorieCalculator from '../../components/CalorieCalculator';
import { LocalStorageService } from '../../helpers/LocalStorage';


const DietClub = () => {
  const userName = LocalStorageService.getUserName();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalCalori, setModalCalori] = useState(true);
  const [boy, setBoy] = useState<any>(160);
  const [kilo, setKilo] = useState<any>(65);
  const [bmi, setBmi] = useState<any>(null);
  const [idealKiloAraligi, setIdealKiloAraligi] = useState<any>(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalCalori(false);
  };

    // BMI Hesaplama Fonksiyonu
    const calculateBMI = (boy:any, kilo:any) => {
      if (boy && kilo) {
        const bmiValue = kilo / ((boy / 100) * (boy / 100));
        setBmi(bmiValue.toFixed(1));
        calculateIdealWeight(boy);
      }
    };
  
    // İdeal Kilo Hesaplama Fonksiyonu
    const calculateIdealWeight = (boy:any) => {
      // İdeal kilo genelde BMI 18.5 - 24.9 arası ideal kabul edilir
      const minIdealWeight = 18.5 * ((boy / 100) ** 2);
      const maxIdealWeight = 24.9 * ((boy / 100) ** 2);
  
      setIdealKiloAraligi({
        min: minIdealWeight.toFixed(1),
        max: maxIdealWeight.toFixed(1),
      });
    };
  
  useEffect(() => {
    calculateBMI(boy,kilo)
  },[boy,kilo])

  return (
  <>
      <Row gutter={16} style={{ marginTop: '30px' }} className='diet-row'>
        {/* Kullanıcı Profili */}
        <Col md={8} xs={24}>
          <Card title={`Hoşgeldin ${userName}`} className='card-diet title' bordered={false}>
            <h5>Boyunuz: {boy} cm</h5>
            <h5>Kilonuz: {kilo} cm</h5>
            <div>
              {bmi && (
                <div className='calculate-weight-result'>
                  <Tooltip
                    className='calculate-tooltip'
                    title={
                      <div>
                        <p>Vücut Kitle İndeksi değerine göre, bir kişinin kilosunun normal olup olmadığını şu şekilde sınıflandırabiliriz:</p>
                        <table className='tabley'>
                          <thead>
                            <tr>
                              <th>Vücut Kitle İndeksi Aralığı</th>
                              <th>Kategori</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>18.5 ve altı</td>
                              <td>Zayıf</td>
                            </tr>
                            <tr>
                              <td>18.5 - 24.9</td>
                              <td>Normal kilolu</td>
                            </tr>
                            <tr>
                              <td>25 - 29.9</td>
                              <td>Kilolu (Fazla Kilo)</td>
                            </tr>
                            <tr>
                              <td>30 ve üzeri</td>
                              <td>Obez (Şişman)</td>
                            </tr>
                          </tbody>
                        </table>
                        <p>Bu tablo, ideal kilo aralığını belirlemenize yardımcı olacaktır.</p>
                        <p>
                          Sonuç olarak, Vücut Kitle İndeksi, sağlıklı bir yaşam tarzı için iyi bir rehber olabilir, ancak bireysel sağlık değerlendirmesi için daha ayrıntılı testler (vücut yağı, kas kütlesi, bel çevresi ölçümü gibi) yapılması önemlidir.
                        </p>
                      </div>
                    }
                  >
                    <h5 style={{ textDecoration: 'underline' }}>Vücut Kitle İndeksi : {bmi}</h5>
                  </Tooltip>
                  <h5>İdeal Kilo Aralığınız: {idealKiloAraligi.min} kg - {idealKiloAraligi.max} kg</h5>
                </div>
              )}
            </div>
            <Button type="primary" style={{ marginTop: '20px', height: '34px' }} onClick={showModal}
              className='calculate-weight-button'>Beden Ölçü Defteri
            </Button>
          </Card>
          <Card title="Günlük Kalori Hesaplama" className='card-diet' bordered={false} style={{ marginTop: '20px' }}>
            <Button type="primary" style={{ marginTop: '20px', height: '34px' }} onClick={showModal}
              className='calculate-weight-button'>Günlük Kalori Hesaplama
            </Button>
          </Card>
        </Col>
      
        <Col md={16} xs={24}>
          <MeasurementNotebook />
        </Col>
      </Row>

      <Divider />

      <Row gutter={16} className='diet-row'>
        <Col md={8} xs={24}>
          <Card title="Aktif Diyet Grupları" className='card-diet' bordered={false}>
            <ul>
              <li><a>- Akdeniz Diyeti</a><span>Katılımcı: 15, Süre: 30 gün</span></li>
              <li><a>- Ketojenik Diyet</a><span>Katılımcı: 10, Süre: 20 gün</span></li>
              <li><a>- Akdeniz Diyeti</a><span>Katılımcı: 15, Süre: 30 gün</span></li>
              <li><a>- Ketojenik Diyet</a><span>Katılımcı: 10, Süre: 20 gün</span></li>
            </ul>
          </Card>
        </Col>
        <Col md={8} xs={24}>
          <Card title="Diyet Konuları" className='card-diet' bordered={false}>
            <ul>
              <li><a href="#">Diyet Tarifi: Zeytinyağlı Sebze</a></li>
              <li><a href="#">Haftalık Diyet Planı</a></li>
            </ul>
          </Card>
        </Col>
        <Col md={8} xs={24}>
          <Card title="Son Eklenen Konular" className='card-diet'  bordered={false}>
            <ul>
              <li><a href="#">Diyet için Motivasyonlar</a></li>
              <li><a href="#">Başarı Hikayeleri</a></li>
            </ul>
          </Card>
        </Col>
      </Row>
  
      <Modal  
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel}
        className='measurement-modal'
        footer={false}
      >
        <MeasurementForm/>
     </Modal>
     <Modal  
        open={modalCalori} 
        onOk={handleOk} 
        onCancel={handleCancel}
        className='measurement-modal'
        footer={false}
      >
        <CalorieCalculator/>
     </Modal>
  </>
     
  );
};

export default DietClub;
