
import React, { useState,useRef,useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopicUserCard from "../../components/TopicUserCard";
import moment from 'moment';
import { Button,  Modal, Form, Input, Typography } from "antd";
import {  CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {getTopicMessageApi, sendTopicMessageApi, setTopicLikesApi, setReplyLikesApi} from '../../services/contents';
import BlogBreadcrumb from "../../components/BlogBreadcrumb";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { LocalStorageService } from "../../helpers/LocalStorage";

const { Text } = Typography;

const DetailPendingApproval = () => {
  const quillRef = useRef<any>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const url = location.pathname.split("/");
  const categoryId = url[2];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const [pageSize, setPageSize] = useState(10);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [totalData, setTotalData] = useState(1);
  const [loading,setLoading] = useState<any>(false);
  const [topicMessage,setTopicMessage] = useState<any>([]);
  const [topicData,setTopicData] = useState<any>([]);
  const [topicId,setTopicId] = useState<any>(''); 
  const [editorContent, setEditorContent] = useState<any>('');
  const [hovered, setHovered] = useState<number | null>(null);
  const [likeShow, setLikeShow] = useState<any[]>([]);

  const userName:any = LocalStorageService.getUserName();
  const users = LocalStorageService.getAuthToken();
  const userImage = LocalStorageService.getUserImage();
  
  const userNameW = userName && userName.split(" "); 
  const userLetters = userNameW?.length && userNameW[0].charAt(0).toUpperCase();

  const  breadcrumbItems = [
    { name: 'Anasayfa', url: '/' },
    { name: `Onay Bekleyen Konu Detayı`, url: `/onay-bekleyen-konu-detay` },
  ];

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("Gönderilen Mesaj:", values.message);
        setIsModalOpen(false);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Hata:", info);
      });
  };



  return (
    <>
      <BlogBreadcrumb breadcrumbItems={breadcrumbItems} />
      <div className="topic-page-title topic-top-div"  >
        <div>
          <h2 style={{maxWidth:'700px'}}>Onay Bekleyen Konu </h2>
        </div>
      </div>
      <div className="blog-card" >
            <div className="cardx">
              <article className="article">
                <div className="right-txtt topic-list-div">
                  <div className="topic-user-profil">
                    <TopicUserCard 
                      userName={'nazli'} 
                      status={true} 
                      mesaj={'debene'} 
                      like={3} 
                      date={'12-12-2008'} 
                      badgeSaveUser={true}  
                      badgeMomClub={true }  
                      badgeDietClub={true} 
                      userRole={'yönetici'} 
                      image= {''} 
                    />
                  </div>
                  <div className="topic-msj">
                  <div>
                      <div className="topic-msj-txt" > Buraya mesaj gelecek</div>
                     
                    </div>
                    <div>
                    <div className="topic-msj-btn" style={{justifyContent:'end'}}>
                       <div className="btn-right" style={{display:'flex'}}>
                        <Button
                          style={{color:'#f00'}}
                          className="deny-btn"
                          icon={<CloseCircleOutlined style={{color:'f00'}} />}
                           onClick={() => setIsModalOpen(true)}
                          >
                              Onaylama
                          </Button>
                          <Button
                              icon={<CheckCircleOutlined />}
                              // onClick={() => handleReply(message)}
                            >
                              Onayla
                            </Button>
                          </div>
                      </div>
                       
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <Modal
            title="Neden Onaylanmadı"
            open={isModalOpen}
            onCancel={handleCancel}
            className="pending-modal"
            closable={false}
            footer={[
              <Button key="cancel" onClick={handleCancel}>
                İptal
              </Button>,
              <Button key="submit" type="primary" onClick={handleOk}>
                Gönder
              </Button>,
            ]}
          >
            <Form form={form} layout="vertical">
              <Form.Item
                name="message"
                rules={[{ required: true, message: "Lütfen bir mesaj girin!" }]}
              >
                <Input.TextArea rows={4} placeholder="Burada, konunun neden onaylanmadığına dair yorumlarınızı paylaşabilirsiniz.." />
              </Form.Item>
            </Form>
          </Modal>

     

    </>
  );
};

export default DetailPendingApproval;
