import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, notification } from 'antd';
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { loginApi  } from "../services/userAuth";
import { LocalStorageService } from "../helpers/LocalStorage";

const Login = () => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [browserInfo, setBrowserInfo] = useState<any>({
        browserName: '',
        browserVersion: '',
        operatingSystem: ''
      });

    const getBrowserInfo = async () => {
        const userAgent = navigator?.userAgent;
        let browserName = '';
        let browserVersion = '';
        let operatingSystem = '';
      
        if (userAgent) {
          if (userAgent.indexOf('Firefox') > -1) {
            browserName = 'Firefox';
            browserVersion = userAgent.match(/Firefox\/([\d.]+)/)?.[1] || 'Unknown';
          } else if (userAgent.indexOf('Chrome') > -1) {
            browserName = 'Chrome';
            browserVersion = userAgent.match(/Chrome\/([\d.]+)/)?.[1] || 'Unknown';
          } else if (userAgent.indexOf('Safari') > -1) {
            browserName = 'Safari';
            browserVersion = userAgent.match(/Version\/([\d.]+)/)?.[1] || 'Unknown';
          } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident/') > -1) {
            browserName = 'Internet Explorer';
            browserVersion = userAgent.match(/(MSIE [\d.]+|rv:[\d.]+)/)?.[1] || 'Unknown';
          }
      
          if (userAgent.indexOf('Windows NT') > -1) {
            operatingSystem = 'Windows';
          } else if (userAgent.indexOf('Mac OS X') > -1) {
            operatingSystem = 'MacOS';
          } else if (userAgent.indexOf('Linux') > -1) {
            operatingSystem = 'Linux';
          } else if (userAgent.indexOf('Android') > -1) {
            operatingSystem = 'Android';
          } else if (userAgent.indexOf('like Mac') > -1) {
            operatingSystem = 'iOS';
          }
        }
      
        setBrowserInfo({
          browserName,
          browserVersion,
          operatingSystem
        });
        return browserInfo
      };

    const onLogin = async (args: any) => {
        if(args.username !== '' && args.password !== ''){
            try{
                setIsLoading(true);
                const response: any = await loginApi(args);
                if(response){
                    LocalStorageService.setAuthToken(response.data.token);
                    LocalStorageService.setRole(response.data.role);
                    LocalStorageService.setUserName(response.data.username);
                    LocalStorageService.setUserImage(response.data.profileImageLink);
                    setIsLoading(false);
                    navigate("/");
                }

            }catch(e:any){
                console.log('hata',e)
                setIsLoading(false);
                if(e.data.status === 404 && e.data.Type.includes('UserNotFoundException')){
                    notification.error({message: 'Kullanıcı bulunamadı.'});
                  }
            }
        }else{
            notification.warning({
                message: `Kullanıcı adınızı yada şifrenizi boş bıraktınız.`,
              });
        }
    }
    useEffect(() => {
        getBrowserInfo();
       }, []);

    return (
        <>
            <Row justify="center">
                <Col xs={24} md={12} lg={12} style={{ textAlign: 'center' }}>
                    <div className='blog-card login-div' >
                        <div className='cardx' style={{ padding: '20px' }}>
                            <article className='article'>
                                <div className='right-txtt'>
                                    <h2 style={{ textAlign: 'center' }}>Giriş Yap</h2>
                                    <div className="signup-form">
                                        <Form name="login" onFinish={onLogin}  layout="vertical" >
                                            <Form.Item
                                                label='Kullanıcı Adınız'
                                                className="email-input"
                                                name="username"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Lütfen kullanıcı adınızı giriniz!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Kullanıcı Adınız" />
                                            </Form.Item>

                                            <Form.Item
                                                label="Şifre"
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Lütfen şifrenizi giriniz!',
                                                    },
                                                ]}
                                            >
                                                <Input.Password
                                                    type={"password"}
                                                    placeholder="Şifre"
                                                    iconRender={(visible: any) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                />
                                            </Form.Item>
                                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                                {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                                                    <Checkbox>Beni Hatırla</Checkbox>
                                                </Form.Item> */}
                                                <a href='/sifremi-unuttum'>Şifremi Unuttum</a>
                                            </div>

                                            <Form.Item className="m-0"   style={{ marginTop: '20px' }}>
                                                <Button
                                                    className="login-btn"
                                                    type="primary"
                                                    htmlType="submit"
                                                    size="large"
                                                    loading={isLoading}
                                                    block
                                                >
                                                    Giriş Yap
                                                </Button>
                                            </Form.Item>

                                    
                                        </Form>

                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Login; 