import React from 'react';
import { Menu } from 'antd';
import {  Link } from "react-router-dom";
import { useLocation } from 'react-router';
import { LocalStorageService } from "../helpers/LocalStorage";

function Submenu() {
    const location = useLocation();
    const { pathname } = location;
    const role = LocalStorageService.getRole();
    console.log('role', role)
    return (
        <>
        {
            pathname === '/' ? (
                <Menu mode="horizontal" defaultSelectedKeys={['0']} className='submenu-style' inlineCollapsed={false}>
                    <Menu.Item key="2">
                        <Link to='/trend-konular'>
                            Trend Konular
                        </Link> 
                    </Menu.Item>
                    <Menu.Item key="3">
                        <Link to='/yeni-eklenen-konular'>
                            Yeni Eklenen Konular
                        </Link> 
                    </Menu.Item>
                    <Menu.Item key="4">
                        <Link to='/yeni-kullanici-mesajlari'>
                            Yeni Kullanıcı Mesajları
                        </Link> 
                    </Menu.Item> 
                    {
                        role === 'Administrator' && (
                        <Menu.Item key="5">
                            <Link to='/onay-bekleyen-konular'>
                                Onay Bekleyen Konular
                            </Link> 
                        </Menu.Item> 
                        )
                    }
                   
            </Menu>
            ): ''
            // pathname === '/blog' ? 'blog meu' : ''
        }
            
        </>
    );
}

export default Submenu;
