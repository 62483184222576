import React, { useState } from "react";
import { Form, Input, Select, Button } from "antd";

const { Option } = Select;

const CalorieCalculator = () => {
  const [calorieResult, setCalorieResult] = useState<any>(null);
  const [macros, setMacros] = useState<any>(null);

  const calculateCalories = (values:any) => {
    const {weight, height, age, activityLevel, goal } = values;

    // BMR Hesaplama (Harris-Benedict Formülü)
    let bmr = 447.6 + 9.2 * weight + 3.1 * height - 4.3 * age;;
    
    // Aktivite Seviyesine Göre Kalori Çarpanı
    const activityMultipliers:any = {
      sedentary: 1.2,
      lightly_active: 1.375,
      moderately_active: 1.55,
      very_active: 1.725,
      extremely_active: 1.9,
    };
    const maintenanceCalories = bmr * activityMultipliers[activityLevel];

    // Hedefe Göre Kalori Hesaplama
    let finalCalories = maintenanceCalories;
    if (goal === "lose_weight") {
      finalCalories -= 500; // Ortalama kilo kaybı için
    } else if (goal === "gain_weight") {
      finalCalories += 500; // Ortalama kas kütlesi kazanımı için
    } else if (goal === "gain_weight_fast") {
      finalCalories += 1000; // Hızlı kilo alımı için
    }

    // Makro Hesaplama
    const proteinCalories = (finalCalories * 0.25) / 4; // %25 protein
    const carbCalories = (finalCalories * 0.50) / 4; // %50 karbonhidrat
    const fatCalories = (finalCalories * 0.25) / 9; // %25 yağ

    setCalorieResult(finalCalories.toFixed(2)); // Sonucu iki ondalık basamakla göster
    setMacros({
      protein: proteinCalories.toFixed(2),
      carbs: carbCalories.toFixed(2),
      fats: fatCalories.toFixed(2),
    });
  };

  return (
    < >
      <h2 style={{ textAlign: "center", marginBottom: "20px", fontWeight: "bold" }}>
        Günlük Kalori ve Makro İhtiyacınızı Hesaplayın
      </h2>
      <Form layout="vertical" onFinish={calculateCalories}>

        <Form.Item
          label="Boy (cm)"
          name="height"
          rules={[{ required: true, message: "Lütfen boyunuzu giriniz!" }]}
        >
          <Input placeholder="Boyunuzu giriniz" />
        </Form.Item>

        <Form.Item
          label="Kilo (kg)"
          name="weight"
          rules={[{ required: true, message: "Lütfen kilonuzu giriniz!" }]}
        >
          <Input placeholder="Kilonuzu giriniz" />
        </Form.Item>

        <Form.Item
          label="Yaş"
          name="age"
          rules={[{ required: true, message: "Lütfen yaşınızı giriniz!" }]}
        >
          <Input placeholder="Yaşınızı giriniz" />
        </Form.Item>

        <Form.Item
          label="Gün İçi Hareketlilik Seviyeniz"
          name="activityLevel"
          rules={[{ required: true, message: "Lütfen aktivite seviyenizi seçiniz!" }]}
        >
          <Select placeholder="Hareketlilik seviyenizi seçin">
            <Option value="sedentary">
              Sedanter (Hareketsiz veya çok az hareket ediyorum.)
            </Option>
            <Option value="lightly_active">
              Az hareketli (Hafif hareketli bir yaşam / Haftada 1-3 gün egzersiz.)
            </Option>
            <Option value="moderately_active">
              Orta derece hareketli (Hareketli bir yaşam / Haftada 3-5 gün egzersiz.)
            </Option>
            <Option value="very_active">
              Çok hareketli (Haftada 6-7 gün egzersiz.)
            </Option>
            <Option value="extremely_active">
              Aşırı hareketli (Profesyonel sporcu veya atlet seviyesi.)
            </Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Hedefiniz Nedir?"
          name="goal"
          rules={[{ required: true, message: "Lütfen hedefinizi seçiniz!" }]}
        >
          <Select placeholder="Hedefinizi seçin">
            <Option value="lose_weight">Zayıflamak, yağ yakmak</Option>
            <Option value="gain_weight">Kilo almak (kas kütlesini artırma)</Option>
            <Option value="gain_weight_fast">
              Hızlı kilo almak (yağ oranınız artabilir)
            </Option>
            <Option value="maintain_weight">Kilomu korumak</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              backgroundColor: "#983961",
              borderColor: "#983961",
              borderRadius: "8px",
              fontWeight: "bold",
            }}
          >
            Hesapla
          </Button>
        </Form.Item>
      </Form>

      {calorieResult && (
        <div
          style={{
            marginTop: "20px",
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Günlük Kalori İhtiyacınız:{" "}
          <span style={{ color: "#983961" }}>{calorieResult} kcal</span>
          <div style={{ marginTop: "10px" }}>
            <p>Protein: {macros?.protein} gram</p>
            <p>Karbonhidrat: {macros?.carbs} gram</p>
            <p>Yağ: {macros?.fats} gram</p>
          </div>
        </div>
      )}
    </>
  );
};

export default CalorieCalculator;
