import React, { useEffect, useState } from "react";
import {  Form, Input, Button, DatePicker, Select, Radio, Col, Row, notification ,Checkbox} from 'antd';
import moment from 'moment';
import 'dayjs/locale/tr'
import '../../node_modules/moment/locale/tr';
import locale from '../../node_modules/antd/es/date-picker/locale/tr_TR';
import {useSelector} from 'react-redux';
import {validatePassword} from '../helpers/validators';
import {  createUserApi, getProfessionsApi, getEducationApi, getCitiesApi, getCaptchaQuestionApi} from "../services/userAuth";

const SignUp = () => {
    // let navigate = useNavigate();
    // const dispatch = useDispatch()
    const [form] = Form.useForm();
    const {userIp} = useSelector((state: any) => state.user);
    const [isLoading, setIsLoading] = useState(false);
    const [professions, setProfessions] = useState([]);
    const [education, setEducation] = useState([]);
    const [cities, setCities] = useState([]);
    const [captchaQuestion, setCaptchaQuestion] = useState<any>({});
    const [dietClub, setDietClub] = useState(false);
    const [momClub, setMomClub] = useState(false);

    const disabledDate = (current:any) => {
      // current tarihini bugünün tarihinden sonra olup olmadığını kontrol et
      return current && current > moment().endOf('day');
    };


    const onFinish = async (values:any) => {
        try{
          if(values.password !== values.passwordConfirmation){
            notification.warning({
              message: `Şifre ve şifre tekrarın birbiriyle aynı değil.`,
            });
          }else{
            setIsLoading(true)
            const response:any = await createUserApi({
              email:values.email,
              userName:values.username,
              password:values.password,
              passwordConfirmation:values.passwordConfirmation,
              dateOfBirth:moment(values.dateOfBirth).format('YYYY-MM-DD'),
              gender:values.gender,
              cityId:values.cityId,
              educationStatusId:values.educationStatusId,
              professionId:values.professionId,
              captchaQuestionId:captchaQuestion?.captchaQuestionId,
              captchaAnswer:values.captchaAnswer,
              dietClub:dietClub,
              tall:values.tall,
              weight:values.weight,
              momClub:momClub,
            });
            console.log('res',response)
            if(response){
              setIsLoading(false);
              notification.success({
                message: `Üyeliğiniz başarıyla oluşturuldu. Üyeliğinizi aktif duruma getirmek için lütfen mail adresinizi kontrol ediniz.`,
                duration: 12
              });
              form.resetFields();
            }
          }
        }catch(e:any){
          setIsLoading(false)
          console.log('hata',e)
          if(e.data.status === 409 && e.data.exceptionType.includes('UsernameAlreadyExistsException')){
            notification.error({message: 'Bu kullanıcı adı zaten kayıtlı. Lütfen başka bir kullanıcı adı giriniz.'});
          }
          if(e.data.status === 409 && e.data.exceptionType.includes('EmailAlreadyExistsException')){
            notification.error({message: 'Bu email zaten kayıtlı. Lütfen başka bir email giriniz.'});
          }
          if(e.data.status === 400 && e.data.exceptionType.includes('PasswordsNotMatchedException')){
            notification.error({message: 'Şifre ve şifre tekranınız eşleşmiyor.'});
          }
        }
      };

  const getProfessions = async () => {
    try{
      const response:any = await getProfessionsApi();
      if(response.status === 200){
        setProfessions(response?.data?.professions)
      }
    }catch(e:any){
      console.log(e)
    }
  }

  const getEducation = async () => {
    try{
      const response:any = await getEducationApi();
      if(response.status === 200){
        setEducation(response?.data?.educationStatuses)
      }
    }catch(e:any){
      console.log(e)
    }
  }

  const getCities = async () => {
    try{
      const response:any = await getCitiesApi();
      if(response.status === 200){
        setCities(response?.data?.cities)
      }
    }catch(e:any){
      console.log(e)
    }
  }

  const getCaptchaQuestion = async () => {
    try{
      const response:any = await getCaptchaQuestionApi();
      if(response.status === 200){
        setCaptchaQuestion(response?.data)
      }
    }catch(e:any){
      console.log(e)
    }
  }

  useEffect(() => {
    getProfessions();
    getEducation();
    getCities();
    getCaptchaQuestion();
  },[])
    return (
        <>
          <Row style={{ justifyContent: 'center' }}>
            <Col span={16}>
              <div className='blog-card'>
                <div className='cardx' style={{ padding: '40px' }}>
                  <article className='article'>
                    <div className='right-txtt'>
                      <h2 style={{ textAlign: 'center' }}>ÜCRETSİZ Kayıt Ol</h2>
                      <div className="signup-form">
                        <Form
                          form={form}
                          name="registration"
                          layout="vertical"
                          onFinish={onFinish}
                          className="setting-from"
                        >
                          {/* Kullanıcı Adı */}
                          <Form.Item
                            label="Kullanıcı Adı / Nickname"
                            name="username"
                            rules={[{ required: true, message: 'Lütfen kullanıcı adı giriniz.' }]}
                          >
                            <Input />
                          </Form.Item>

                          {/* E-posta */}
                          <Form.Item
                            label="E-posta"
                            name="email"
                            rules={[{ required: true, message: 'E-posta zorunludur!', type: 'email' }]}
                          >
                            <Input />
                          </Form.Item>

                          {/* Şifre */}
                          <Form.Item
                            label="Şifre"
                            name="password"
                            rules={[{ required: true, validator: (_, value) => validatePassword(value) }]}
                          >
                            <Input.Password />
                          </Form.Item>

                          {/* Şifre Tekrar */}
                          <Form.Item
                            label="Şifre Tekrar"
                            name="passwordConfirmation"
                            rules={[{ required: true, validator: (_, value) => validatePassword(value) }]}
                          >
                            <Input.Password />
                          </Form.Item>

                          {/* Doğum Tarihi */}
                          <Form.Item
                            label="Doğum Tarihi"
                            name="dateOfBirth"
                            rules={[{ required: true, message: 'Doğum tarihi zorunludur!' }]}
                          >
                            <DatePicker picker="date" locale={locale} format="DD/MM/YYYY" placeholder="Tarih Seçiniz" disabledDate={disabledDate} />
                          </Form.Item>

                          {/* Cinsiyet */}
                          <Form.Item
                            label="Cinsiyet"
                            name="gender"
                            rules={[{ required: true, message: 'Cinsiyet zorunludur!' }]}
                          >
                            <Radio.Group>
                              <Radio value="female">Kadın</Radio>
                              <Radio value="male">Erkek</Radio>
                            </Radio.Group>
                          </Form.Item>

                          {/* Eğitim Durumu */}
                          <Form.Item
                            label="Eğitim Durumu"
                            name="educationStatusId"
                            rules={[{ required: true, message: 'Eğitim durumu zorunludur!' }]}
                          >
                            <Select placeholder="Eğitim durumu seçiniz">
                              {
                                education && education.map((item:any) => {
                                  return(
                                    <Select.Option key={item.educationStatusId} value={item.educationStatusId}>{item.status}</Select.Option>
                                  )
                                })
                              }
                            </Select>
                          </Form.Item>

                          {/* Meslek */}
                          <Form.Item
                            label="Mesleğiniz"
                            name="professionId"
                            rules={[{ required: true, message: 'Meslek bilginiz zorunludur!' }]}
                          >
                            <Select placeholder="Mesleğinizi seçiniz.">
                              {
                                professions && professions.map((item:any) => {
                                  return(
                                    <Select.Option key={item.professionId} value={item.professionId}>{item.name}</Select.Option>
                                  )
                                })
                              }
                            </Select>
                          </Form.Item>

                          {/* Şehir */}
                          <Form.Item
                            label="Şehir"
                            name="cityId"
                            rules={[{ required: true, message: 'Şehir zorunludur!' }]}
                          >
                            <Select placeholder="Şehir seçiniz">
                              {
                                cities  && cities.map((item:any) => {
                                  return(
                                    <Select.Option key={item.cityId} value={item.cityId}>{item.name}</Select.Option>
                                  )
                                })
                              }
                            </Select>
                          </Form.Item>

                          {/* Güvenlik Sorusu */}
                          <Form.Item
                            label="Güvenlik Sorusu"
                            name="captchaQuestionId"
                          >
                            <Input type="text" value={captchaQuestion?.captchaQuestionId} placeholder={captchaQuestion?.question} disabled={false}></Input>
                          </Form.Item>

                          {/* Güvenlik Sorusu Cevap */}
                          <Form.Item
                            label="Güvenlik Sorusu Cevabı"
                            name="captchaAnswer"
                            rules={[{ required: true, message: 'Güvenlik sorusu cevabını giriniz!' }]}
                          >
                            <Input />
                          </Form.Item>

                           {/* Kulüp ve Abonelik Seçenekleri */}
                           <Form.Item name="membershipOptions" label="Kulüpler">
                              <Checkbox.Group>
                                <Row>
                                  <Col span={24}>
                                    <Checkbox 
                                      value="momClub"
                                      onChange={(e) => setMomClub(e.target.checked)}
                                    >Anneler Kulübüne Katılmak İstiyorum</Checkbox>
                                  </Col>
                                  <Col span={24}>
                                    <Checkbox
                                      value="dietClub"
                                      onChange={(e) => setDietClub(e.target.checked)}
                                    >
                                      Diyet Kulübüne Katılmak İstiyorum
                                    </Checkbox>
                                  </Col>
                                </Row>
                              </Checkbox.Group>
                            </Form.Item>

                          {/* Diyet Bilgileri */}
                          {dietClub && (
                            <>
                              <Form.Item label="Boy (cm)" name="tall">
                                <Input placeholder="Boyunuzu giriniz" />
                              </Form.Item>
                              <Form.Item label="Kilo (kg)" name="weight">
                                <Input placeholder="Kilonuzu giriniz" />
                              </Form.Item>
                            </>
                          )}

                          {/* Kayıt Ol Button */}
                          <Form.Item style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Button type="primary" htmlType="submit" loading={isLoading}>
                              Kayıt Ol
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </Col>
          </Row>
        </>
    )
}

export default SignUp; 