
import {api} from '../services/apis';
import {LocalStorageService} from '../helpers/LocalStorage';

const headers = {
  headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }
}

const getTopCategoriesApi = async (): Promise<Object> => {
  return await api.get(`categories`, headers
  );
};

const getSubCategoriesApi = async (categoryId:any): Promise<Object> => {
  return await api.get(`categories/${categoryId}/subcategories`, headers
  );
};

const setTopics = async (data: any): Promise<Object> => {
  return (
    await api.post(`topics`, data, headers)
  );
};

const getCategoriesDetailsApi = async (): Promise<Object> => {
  return await api.get(`categories/details`, headers
  );
};

const getSubcategorieDetailsApi = async (categoryId:any): Promise<Object> => {
  return await api.get(`categories/${categoryId}/subcategories/details`, headers
  );
};

const getTopicMessageApi = async (categoryId:any,currentPage:any,pageSize:any ): Promise<Object> => {
  return await api.get(`topics/${categoryId}/messages?size=${pageSize}&page=${currentPage}`, headers
  );
};

const sendTopicMessageApi = async (topicId:any,content:any): Promise<Object> => {
  return await api.post(`topics/${topicId}/reply`, {content}, headers
  );
};

const getTopicListApi = async (categoryUrl:any,currentPage:any,pageSize:any ): Promise<Object> => {
  return await api.get(`subcategories/${categoryUrl}/topics?size=${pageSize}&page=${currentPage}`, headers
  );
};

const setTopicLikesApi = async (id:any): Promise<Object> => {
  return await api.post(`topics/${id}/like`, null, headers
  );
};

const setReplyLikesApi = async (id:any): Promise<Object> => {
  return await api.post(`replies/${id}/like`, null, headers
  );
};

const changePasswordApi = async (values: any): Promise<Object> => {
  return (
    await api.patch(`users/change-password`, values, headers )
  );
};

const getTopicsOpenedApi = async (pageSize:any, currentPage:any): Promise<Object> => {
  return await api.get(`users/topics?size=${currentPage}&page=${pageSize}`, headers
  );
};

const getTopicPopularApi = async (pageSize:any, currentPage:any): Promise<Object> => {
  return await api.get(`topics/popular?size=${currentPage}&page=${pageSize}`, headers
  );
};

const getNewlyAddedTopicsApi = async (pageSize:any, currentPage:any): Promise<Object> => {
  return await api.get(`topics/newest?size=${currentPage}&page=${pageSize}`, headers
  );
};

const getNewUserMessagesApi = async (pageSize:any, currentPage:any): Promise<Object> => {
  return await api.get(`topics/last-replied?size=${currentPage}&page=${pageSize}`, headers
  );
};

const getTopicsSearchApi = async (query:any ,pageSize:any, currentPage:any): Promise<Object> => {
  return await api.get(`topics/search?query=${query}&?size=${pageSize}&page=${currentPage}`, headers
  );
};

const getTopicsSearchUserApi = async (userName:any ,pageSize:any, currentPage:any): Promise<Object> => {
  return await api.get(`topics/search-by-users?${userName}&size=${pageSize}&page=${currentPage}`, headers
  );
};

export {
  getTopCategoriesApi,
  getSubCategoriesApi,
  setTopics,
  getCategoriesDetailsApi,
  getSubcategorieDetailsApi,
  getTopicMessageApi,
  sendTopicMessageApi,
  getTopicListApi,
  setTopicLikesApi,
  setReplyLikesApi,
  changePasswordApi,
  getTopicsOpenedApi,
  getTopicPopularApi,
  getNewlyAddedTopicsApi,
  getNewUserMessagesApi,
  getTopicsSearchApi,
  getTopicsSearchUserApi
}