import {api} from '../services/apis';
import {LocalStorageService} from '../helpers/LocalStorage';

const headers = {
  headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }
}
const getMessageApi = async (currentPage:any,pageSize:any ): Promise<Object> => {
  return await api.get(`messages?size=${pageSize}&page=${currentPage}`, headers );
};

const getMessageUserNameApi = async (userName:any, currentPage:any,pageSize:any ): Promise<Object> => {
  return await api.get(`messages/${userName}?size=${pageSize}&page=${currentPage}`, headers );
};

const sendMessageApi = async (userName:any, content:any): Promise<Object> => {
  return await api.post(`messages/${userName}/send`, {content}, headers );
};

const deleteMessageApi = async (userName:any): Promise<Object> => {
  return await api.delete(`messages/${userName}`, '', headers);
};

const searchUserApi = async ( searchInput:any ): Promise<Object> => {
  return await api.get(`users/search/${searchInput}`, headers);
};

const getNotificationsApi = async ( currentPage:any,pageSize:any ): Promise<Object> => {
  return await api.get(`notifications?size=${pageSize}&page=${currentPage}`, headers );
};

const notificationsTriggerApi = async ( notificationId:any): Promise<Object> => {
  return await api.patch(`notifications/${notificationId}`, '', headers );
};

const notificationsUnreadApi = async (): Promise<Object> => {
  return await api.get(`notifications/unread?size=2&page=1`, headers);
};

const getProfileApi = async (): Promise<Object> => {
  return await api.get(`users/profile`, headers);
};

const sendProfilePhotoApi = async ( formData:any): Promise<Object> => {
  return await api.post(`users/profile/photo`, formData,headers );
};

const sendProfileBackPhotoApi = async ( formData:any): Promise<Object> => {
  return await api.post(`users/profile/background-photo`, formData,headers);
};

const editColorApi = async ( messageColor:any): Promise<Object> => {
  return await api.patch(`users/profile/message-color`, messageColor, headers);
};

const getSecuritySettingsApi = async (): Promise<Object> => {
  return await api.get(`users/security-settings`, headers);
};

const editSecuritySettingsApi = async ( data:any): Promise<Object> => {
  return await api.put(`users/security-settings`, data, headers );
};

const editProfileMessageApi = async ( profileMessage:any): Promise<Object> => {
  return await api.patch(`users/profile/message`, {profileMessage}, headers );
};

const getMessagesCountApi = async (): Promise<Object> => {
  return await api.get(`messages/unread-count`, headers);
};
const getUserStatisticsApi = async (): Promise<Object> => {
  return await api.get(`users/statistics`, headers);
};

const deleteNotificationApi = async (notificationId:any): Promise<Object> => {
  return await api.delete(`notifications?${notificationId}`, '', headers);
};



export {
  getMessageApi,
  sendMessageApi,
  getMessageUserNameApi,
  getNotificationsApi,
  deleteMessageApi,
  searchUserApi,
  notificationsTriggerApi,
  notificationsUnreadApi,
  getProfileApi,
  sendProfilePhotoApi,
  sendProfileBackPhotoApi,
  editColorApi,
  getSecuritySettingsApi,
  editSecuritySettingsApi,
  editProfileMessageApi,
  getMessagesCountApi,
  getUserStatisticsApi,
  deleteNotificationApi
}