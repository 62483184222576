import React, { useEffect, useState} from "react";
import {Dropdown , Badge, Menu, Avatar, Button, Drawer, Grid, Divider} from "antd";
import { MenuOutlined } from "@ant-design/icons";
import moment from 'moment';
import { useNavigate, Link, useLocation } from "react-router-dom";
import Logo from "../assets/logo.png";
import SearchHeader from "../components/SearchHeader";
import {  MailOutlined , NotificationFilled} from "@ant-design/icons";
import { LocalStorageService } from "../helpers/LocalStorage";
import {notificationsUnreadApi, getMessagesCountApi, getUserStatisticsApi} from '../services/profile';

const Header = () => {
  const [notification, setNotification] = useState([]);
  const [statistics, setStatistics] = useState<any>({});
  const [messagesCount, setMessagesCount] = useState('');
  let navigate = useNavigate();
  const location = useLocation();
  const { useBreakpoint } = Grid;
  const { SubMenu } = Menu;
  const screens = useBreakpoint();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const userNameT:any = LocalStorageService.getUserName();
  const users = LocalStorageService.getAuthToken();
  const userImage = LocalStorageService.getUserImage();
  const token = LocalStorageService.getAuthToken();

  const userName = userNameT && userNameT.split(" "); 
  const userLetters = userName?.length && userName[0].charAt(0).toUpperCase();
 
  const onClickLogout = () => {
    navigate("/giris");
    LocalStorageService.removeAuthToken();
    LocalStorageService.removeBrowserCity();
    LocalStorageService.removeBrowserCountry();
    LocalStorageService.removeBrowserVersion();
    LocalStorageService.removeBrowserIp();
    LocalStorageService.setRole('Guest');
    LocalStorageService.removeUserName();
    LocalStorageService.removeUserImage();
    // LocalStorageService.removeVisitorId();
  };

  const LoginClick = () => { 
    navigate("/giris");
  }

  const RecordClick = () => { 
    navigate("/kayit-ol");
  }

  const notificationsUnread = async () => {
    try{
      const response:any = await notificationsUnreadApi();
      if(response){
        setNotification(response.data.notifications)
      }

    }catch(e:any){
      console.log(e)
    }
  }

  const getMessagesCount = async () => {
    try{
      const response:any = await getMessagesCountApi();
      if(response){
        setMessagesCount(response?.data)
      }
    }catch(e:any){
      console.log(e)
    }
  }

  const getUserStatistics = async () => {
    try{
      const response:any = await getUserStatisticsApi();
      if(response){
        setStatistics(response?.data)
      }
    }catch(e:any){
      console.log(e)
    }
  }

  const UserMenu = () => (
      <Menu style={{padding:'15px'}}>
      <div className="user-menu-box">
        <div className="user-menu-box-avatar">
            {
              userImage !== 'null' ? 
                <Avatar size={100}  src={`${userImage}`} />
                  :
                <Avatar size={100}  icon={userLetters} />
            }
        </div>
        <div>
          <span className="user-title">{userNameT}</span>
          <div className="user-content">
					<dl>
						<dt>Konu Sayısı</dt>
						<dd>{statistics?.createdTopicsCount}</dd>
					</dl>
					<dl>
						<dt>Beğeni Sayısı</dt>
						<dd>{statistics?.takenLikesCount}</dd>
					</dl>
						<dl>
							<dt>Mesaj Sayısı</dt>
							<dd>{statistics?.createdMessagesCount}</dd>
						</dl>
				</div>
        </div>
      </div>
      <Menu.Divider />
      <Menu.Item key="0">
        <Link to="/profilim/mesajlarim" >Profilim</Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/ayarlar" >Ayarlar</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={onClickLogout}>
        Çıkış
      </Menu.Item>
    </Menu>
  );

  const Notification = () => (
    <Menu style={{padding:'15px', marginTop:'8px'}} className="notification-menu">
      <h4 style={{margin:'0'}}>Bildirimler</h4>
      <Divider style={{marginTop:'10px', marginBottom:'0'}}/>
      {
        notification === null ?
        <>
        <p style={{paddingTop:'5px', fontSize:'11px'}}>Yeni bildiriminiz bulunmamaktadır.</p>
        <Divider style={{marginTop:'10px', marginBottom:'0'}}/>
        </>
        :
       notification.map((item:any) => {
          return(
            <div className="notification-menu-css">
              <div style={{width:'30px'}}>
                {
                  userImage !== null ? 
                    <Avatar  src={`${userImage}`} />
                      :
                    <Avatar  icon={userNameT.slice(0, 1).toUpperCase()} />
                }
              </div>
              <div key={item.notificationId}>
                <p>{item.notificationContent}</p>
                <div className="date">
                  <span>{moment(item.notificationDate).format('DD-MM-YYYY')}</span> 
                </div>
              </div>
          </div>
          )
        })
      }
    <div style={{marginTop:'5px'}}>
      <a href="/profilim/bildirimler">Tüm Bildirimleri Görüntüle</a>
    </div>
  </Menu>
);

  const menu = (
    <Menu
      mode="horizontal"
      style={{
        display: "flex",
        justifyContent: "center",
        border: "none",
      }}
      selectedKeys={[location.pathname]}
    >
      <Menu.Item key="/">
        <Link to="/" >Kadınca Gündem</Link>
      </Menu.Item>
      <Menu.Item key="/blog">
        <Link to="/blog">Blog</Link>
      </Menu.Item>
      <Menu.Item key="/diyet-kulubu">
        <Link to="/diyet-kulubu">Diyet Kulubü</Link>
      </Menu.Item>
      <Menu.Item key="/anneler-kulubu">
        <Link to="/anneler-kulubu">Anneler Kulubü</Link>
      </Menu.Item>
      {/* <Menu.SubMenu key="SubMenu" title="Üyeler">
        <Menu.Item key="setting:1">Option 1</Menu.Item>
        <Menu.Item key="setting:2">Option 2</Menu.Item>
      </Menu.SubMenu> */}
    </Menu>
  );

  const mobilMenu = (
    <Menu style={{ width: '100%', marginTop:'50px' }} mode="inline">
      <Menu.Item key="/">
        <Link to="/" style={{ fontWeight: '600' }} >
          Kadınca Gündem
        </Link>
      </Menu.Item>
      <Menu.Item key="m1">
        <Link to="/blog" style={{ fontWeight: '600' }}>
          Blog
        </Link>
      </Menu.Item>
      <Menu.Item key="m3">
        <Link to="/diyet-kulubu" style={{ fontWeight: '600' }}>
          Diyet Kulubü
        </Link>
      </Menu.Item>
      <Menu.Item key="m4">
        <Link to="/anneler-kulubu" style={{ fontWeight: '600' }}>
         Anneler Kulubü
        </Link>
      </Menu.Item>
      <SubMenu
        key="sub5"
        title={
          <span>
            <span>Üyeler</span>
          </span>
        }
      >
        <Menu.Item key="m1">
          <Link to={''}>
            Option 1
          </Link>
        </Menu.Item>
        <Menu.Item key="m2">
          <Link to={''}>
          Option 2
          </Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  )

  const AppHeader = () => {
    const userName = userNameT && userNameT.split(" "); 
    const userLetters = userName?.length && userName[0].charAt(0).toUpperCase();
    return (
        <Dropdown overlay={<UserMenu />} trigger={['click']}>
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
           
            {
              userImage !== 'null' ? 
                <Avatar  src={`${userImage}`} />
                  :
                <Avatar  icon={userLetters} />
            }
            <span style={{ color: 'black', marginLeft: '8px' , fontSize:'12px'}}>{userName}</span>
          </div>
        </Dropdown>
    );
  };

  const handleClikMailBox = () => {
    navigate("/profilim/mesajlarim");
  }

  useEffect(() => {
    if(!!token){
      notificationsUnread();
      getMessagesCount();
      getUserStatistics();
  
     const intervalId = setInterval(() => {
      getMessagesCount();
    }, 6000);
  
    return () => clearInterval(intervalId);
    }
  },[token])

  return (
   <header>
      <div className="header-top">
        <div className="header-center">
          {
            users ? (
              <div className="use-account">
                <div className="account">
                  <AppHeader />
                </div>
                <div className="user-mail" onClick={handleClikMailBox} style={{cursor:'pointer'}}>
                <Badge count={messagesCount && messagesCount}>
                  <MailOutlined />
                </Badge>
                </div>
                <div className="user-notification">
                <Badge count={notification && notification.length}>
                  <Dropdown 
                    overlay={<Notification />} 
                    trigger={['click']}
                    placement="bottomCenter" 
                    >
                    <NotificationFilled />
                  </Dropdown>
                </Badge>
                </div>
                <div className="user-search">
                <SearchHeader/>
                </div>
              </div>
            ):
            (
              <div className="use-account not-uesr">
                <div className="user-mail">
                  <Button onClick={LoginClick}>Giriş Yap</Button>
                </div>
                <div className="user-notification">
                  <Button onClick={RecordClick}>ÜCRETSİZ Üye Ol</Button>
                </div>
                <div className="user-search">
                <SearchHeader/>
                </div>
              </div>
            )
          }
          
        </div>
      </div>
      <div className="header-bottom">
        <div className="header-center-bottom">
          <div className="header-logo">
            <Link to='/'>
              <img src={Logo} width={250} style={{top:'-55px', position:'absolute'}}></img>
            </Link>
            </div>
          <div className="header-menu" style={{ display: 'flex'}}>
            <div>
              {screens.md ? ( // Masaüstü menüsü
                menu
              ) : (
                // Mobil menüsü
                <div className="mobile-menu">
                  <Button
                    icon={<MenuOutlined />}
                    type="primary"
                    onClick={() => setDrawerOpen(true)}
                    style={{ display: "block", margin: "0 auto", textAlign: "center" }}
                  />
                  <Drawer
                    title="Menu"
                    placement="left"
                    width={280}
                    onClose={() => setDrawerOpen(false)}
                    open={isDrawerOpen}
                    bodyStyle={{ padding: 0 }}
                  >
                    {mobilMenu}
                  </Drawer>
                </div>
              )}
            </div>
          </div>

        </div>
      </div>
   </header>
  );
}

export default Header;
