import React, { useEffect, useState } from "react";
import { Table, Typography, Divider } from "antd";
import {getTopicsOpenedApi} from '../../services/contents';
import moment from "moment";

const { Title } = Typography;

const MyTopics = () => {
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalDataCount, setTotalDataCount] = useState(1)
  const [topics, setTopics] = useState([]);

     // Tablo kolonları
  const columns:any = [
    {
      title: "Konu Başlığı",
      dataIndex: "topicTitle",
      key: "topicTitle",
      render: (text: any, record: any) => <a href={`/topics/${record.topicSeoLink}`}>{text}</a>
    },
    {
      title: "Açılma Tarihi",
      dataIndex: "topicCreatedDate",
      key: "topicCreatedDate",
      render: (value:any) => moment(value).format('DD-MM-YYYY')
    },
    {
      title: "Yorum Sayısı",
      dataIndex: "totalViewCount",
      key: "totalViewCount",
      align: "center",
    },
    {
      title: "Mesaj Sayısı",
      dataIndex: "totalMessageCount",
      key: "totalMessageCount",
      align: "center",
    },
  ];

  const getTopicsOpened = async () => {
    try{
      const response:any = await getTopicsOpenedApi(currentPage,pageSize);
      if(response.status === 200){
        setTopics(response?.data?.topics);
        setTotalDataCount(response?.data?.totalDataCount)
      }
    }catch(e:any){
      console.log(e)
    }
  }

  const handleShowSize = (page:any,size:any) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  useEffect(() => {
    getTopicsOpened();
  },[pageSize,currentPage])

  return (
   <>
     <div>
          <Title level={5} className="profile-msg-title">Açtığım Konular</Title>
          <Divider/>
          <Table
            columns={columns}
            dataSource={topics}
            rowKey="id"
            pagination={{
              pageSize: pageSize,
              total:totalDataCount,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "15"],
              showTotal: (totalDataCount) => `Toplam ${totalDataCount} konu`,
              onChange: handleShowSize
            }}
          />
        </div>
   </>
  );
};

export default MyTopics;
