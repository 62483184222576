import React,{useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Tag, Row, Col, Checkbox, Select, Spin } from 'antd';
import { useSelector} from 'react-redux';
import Editor from '../../components/Editor';
import {getSubCategoriesApi, setTopics} from '../../services/contents'

const TopicForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [tags, setTags] = React.useState<any>([]);
  const [htmlContent, setHtmlContent] = useState<any>();
  const [topicTracking, setTopicTracking] = useState<any>(false);
  const [emailTracking, setEmailTracking] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [subCategories, setSubCategories] = useState<any>([]);

  const {topCategories} = useSelector((state: any) => state.topicForm);

  const onChangeTopicTracking = (checkedValues:any) => {
    setTopicTracking( checkedValues.target.checked)
  };
  const onChangeEmailTracking = (checkedValues:any) => {
    setEmailTracking( checkedValues.target.checked)
  };

  const getCategryId = async (value:any) => {
    form.resetFields(['categoryAlt']);
     try{
      setLoading(true)
      const response:any = await getSubCategoriesApi(value)
      if(response){
        setSubCategories(response.data.data)
        setLoading(false)
      }
    }catch(e:any){
      console.log('e',e)
      setLoading(false)
    }
    
  } 

  const handleFinish = async (values:any) => {
    console.log('values',values)
    setLoading(true)
   try{
    const response:any = await setTopics({
      categoryId: values.category,
      subcategoryId:values.categoryAlt,
      title:values.title,
      content:htmlContent,
      labels:values.tags,
      siteNotificationEnabled:topicTracking,
      emailNotificationEnabled:emailTracking
    });
    if(response){
      form.resetFields();
      setEmailTracking(false);
      setTopicTracking(false);
      setHtmlContent('');
      setLoading(false);
      navigate("/")
    }
   }catch(e:any){
    console.log('e',e)
    setLoading(false)
   }
  };

  const handleTagClose = (removedTag:any) => {
    setTags(tags.filter((tag:any) => tag !== removedTag));
  };

  const handleTagInputChange = (e:any) => {
    const newTag = e.target.value;
    if (e.key === 'Enter' && newTag) {
      setTags([...tags, newTag]);
      e.target.value = '';
    }
  };
  
  return (
    
    <Row  justify="center">
      <Col  xs={24} md={24} lg={24} style={{ textAlign: 'center' }}>
        
      {/* <img alt='' src={`home/${categories[5]?.iconPath}`} width='20px'></img> */}

        <div className='blog-card'>
          <div className='cardx' style={{ padding: '20px' }}>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleFinish}
              initialValues={{ tags: [] }}
              className='editor-css'
            >
              <Form.Item
                label="Konu Başlığı Seçiniz"
                name="category"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen başlığı seçiniz!',
                  },
                ]}
              >
                <Select
                  optionFilterProp="children"
                  showSearch
                  placeholder="Konu başlığı seçiniz."
                  style={{textAlign:'left'}}
                  className='top-categry'
                  onChange={getCategryId}
                >
                  {
                    topCategories && topCategories.map((item:any,index:any) => {
                      return(
                        <Select.Option key={index} value={item.categoryId}>
                          <span style={{color:'#c05381'}}>{item.name}</span>
                          <pre style={{whiteSpace:'normal'}}>{item.description}</pre>
                        </Select.Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>

              <Form.Item
                label="Konu Alt Başlığı Seçiniz"
                name="categoryAlt"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen konu alt başığı seçiniz!',
                  },
                ]}
              >
                {loading ? (
                  <Spin spinning={loading}>
                    <Select disabled style={{ width: 200 }} placeholder="Loading..." />
                  </Spin>
                ) : (
                  <Select
                    optionFilterProp="children"
                    showSearch
                    placeholder="Konu alt başlığı seçiniz."
                    style={{ textAlign: 'left' }}
                    className='top-categry'
                    loading={loading}
                  >
                    {
                      subCategories && subCategories.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item.subcategoryId}>
                            <span style={{ color: '#c05381' }}>{item.name}</span>
                            <pre style={{ whiteSpace: 'normal' }}>
                              {item.description}
                            </pre>
                          </Select.Option>
                        )
                      })
                    }
                  </Select>
                )}
              </Form.Item>

              <Form.Item
                label="Konu Başlığı"
                name="title"
                rules={[{ required: true, message: 'Lütfen konu başlığını girin!' }]}
              >
                <Input placeholder="Konu başlığını girin" style={{color:'#c05381'}} />
              </Form.Item>

              <Editor setHtmlContent={setHtmlContent} htmlContent={htmlContent} />

              <Form.Item
                label="Etiketler"
                name="tags"
              >
                <div style={{color:'#c05381'}} >
                  {tags.map((tag: any) => (
                    <Tag
                      key={tag}
                      closable
                      onClose={() => handleTagClose(tag)}
                    >
                      {tag}
                    </Tag>
                  ))}
                  <Input
                    placeholder="Birden fazla etiketi araya virgül koyarak giriniz."
                    onKeyDown={handleTagInputChange}
                  />
                </div>
              </Form.Item>

              <Form.Item 
                name='topicTracking' 
                style={{ textAlign:'left', marginBottom:'5px'}}
                >
                <Checkbox  checked={topicTracking} onChange={onChangeTopicTracking}/>
                <span style={{marginLeft:'6px'}}>Konu takibi için bildirim almak istiyorum.</span>
              </Form.Item>  

              <Form.Item 
                name='emailTracking' 
                style={{ textAlign:'left'}}
              >
                <Checkbox  checked={emailTracking}  onChange={onChangeEmailTracking}/>
                <span style={{marginLeft:'6px'}}>Konu takibi için e-posta almak istiyorum.</span>
              </Form.Item>  

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Gönder
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Col>
    </Row>
   
  );
};

export default TopicForm;
