import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import Cropper from "react-easy-crop";
import {  Button } from "antd";
import { cropImage } from "./cropUtils";

// Base64'den FormData'ya dönüşüm fonksiyonu
const base64ToFormData = (base64Data: string, mimeType: string) => {
  const byteCharacters = atob(base64Data.split(',')[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    byteArrays.push(new Uint8Array(byteNumbers));
  }

  // Blob'a dönüştürülüyor
  const fileBlob = new Blob(byteArrays, { type: mimeType });

  // FormData objesi oluşturuluyor
  const formData = new FormData();
  formData.append('file', fileBlob, 'image.jpg'); // 'image.jpg' dosya adı

  return formData;
};

const ImageUploadingButton = ({ value, onChange, ...props }:any) => {
  return (
   <div style={{display:'flex', flexDirection:'column', paddingLeft:'30px'}}>
   <h4 style={{padding:'0', margin:'0', marginBottom:'10px'}}>Profil Avatarı Kullan</h4> 
   <p>Avatarınızı oluşturmak için aşağıdaki linkten fotoğrafınızı seçin. Fotoğrafın nasıl görüneceğini belirlemek için sol alanda resmi sürükleyebilir veya yakınlaştırabilirsiniz.</p> 
   
    <ImageUploading value={value} onChange={onChange}>
      {({ onImageUpload, onImageUpdate }) => (
        <Button
          color="primary"
          style={{marginTop:'30px', height:'30px', backgroundColor:'#c05381', color:'#fff', borderColor:'#c05381'}}
          onClick={value ? onImageUpload : () => onImageUpdate(0)}
          {...props}
        >
         Yeni Avatar Yükle
        </Button>
      )}
    </ImageUploading>
   </div>
  );
};

const ImageCropper = ({
  open,
  image,
  onComplete,
  containerStyle,
  onClose,
  ...props
}:any) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  return (
    <div style={{display:'flex', flexDirection:'column-reverse'}}>
     <Button
        color="primary"
        disabled={!image}
        style={{marginTop:'10px', height:'30px'}}
        onClick={async () => {
          // Resmi kırpma işlemi
          const croppedImage:any = await cropImage(image, croppedAreaPixels, console.log);

          // Base64'den FormData'ya dönüşüm
          const formData = base64ToFormData(croppedImage, 'image/jpeg');
          
          // onComplete fonksiyonuna FormData'yı gönderiyoruz
          onComplete(formData);

          // Kapanma işlemi
          if (onClose) onClose();
        }}
      >
        Tamamla
      </Button>

    <div style={containerStyle} >
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={setZoom}
            {...props}
          />
        </div>

       
    </div>
        
     
  );
};

const ImageUploader = ({ onCroppedImage, onClose, po }:any) => {
  const [image, setImage] = useState<any>([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div style={{width:'100%', display:'flex'}}>
     
      <ImageCropper
        image={image.length > 0 ? image[0].dataURL : null}
        onComplete={(formData: any) => {
          // Kesilen resmi FormData olarak alıyoruz
          onCroppedImage(formData);
          setDialogOpen(false);
          if (onClose) onClose();
        }}
        onClose={() => {
          setDialogOpen(false);
          if (onClose) onClose();
        }}
        containerStyle={{
          position: "relative",
          width: 120,
          height: 120,
          backgroundImage: `url(${po})`,
          borderRadius: '50%',
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
       <ImageUploadingButton
        value={image}
        onChange={(newImage:any) => {
          setDialogOpen(true);
          setImage(newImage);
        }}
      />
     
    </div>
  );
}
export default ImageUploader;