/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

interface TopicState {
  topCategories: any;
  searchResult:any;
  searchPageSize:any;
  searchCurrentPage:any;
  searchTotalDataCount:any;
}

const initialState = {
  topCategories: [],
  searchResult:null,
  searchPageSize:10,
  searchCurrentPage:1,
  searchTotalDataCount:1,

} as TopicState;

const topicSlice = createSlice({ 
  name: 'topicForm',
  initialState,
  reducers: {
    setTopCategories(state, action) {
      state.topCategories = action.payload;
    },
    setSearchResult(state, action) {
      state.searchResult = action.payload;
    },
    setSearchPageSize(state, action) {
      state.searchPageSize = action.payload;
    },
    setSearchCurrentPage(state, action) {
      state.searchCurrentPage = action.payload;
    },
    setSearchCTotalDataCount(state, action) {
      state.searchTotalDataCount = action.payload;
    },
  },
});

export const { setTopCategories, setSearchResult,setSearchPageSize,setSearchCurrentPage,setSearchCTotalDataCount } = topicSlice.actions;
export default topicSlice.reducer;
