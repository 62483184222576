import React, { useState } from "react";
import { Form, Input, DatePicker, Button, Row, Col, Card, Table } from "antd";
import dayjs from "dayjs";

const MeasurementNotebook = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState<any>([]);

  const onFinish = (values:any) => {
    // Yeni kaydı tabloya ekle
    const newData = {
      key: data.length + 1,
      date: values.date.format("YYYY-MM-DD"),
      weight: values.weight,
      chest: values.chest,
      waist: values.waist,
      hips: values.hips,
    };
    setData([newData, ...data]); // En yeni kayıt en üstte görünsün
    form.resetFields(); // Formu temizle
  };

  // Tablo sütunları
  const columns:any = [
    {
      title: "Tarih",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "Kilo (kg)",
      dataIndex: "weight",
      key: "weight",
      align: "center",
    },
    {
      title: "Göğüs (cm)",
      dataIndex: "chest",
      key: "chest",
      align: "center",
    },
    {
      title: "Bel (cm)",
      dataIndex: "waist",
      key: "waist",
      align: "center",
    },
    {
      title: "Basen (cm)",
      dataIndex: "hips",
      key: "hips",
      align: "center",
    },
  ];

  return (
   
       
        <Card
          bordered={false}
          className="measurement-note"
        >
          <h3 style={{ textAlign: "center",fontWeight: "bold" }}>
          Beden Ölçü Defteri Kayıtlar
          </h3>
          <Table
            dataSource={data}
            columns={columns}
            pagination={{ pageSize: 5 }}
            bordered
            style={{
              background: "#ffffff",
              borderRadius: "8px",
            }}
          />
        </Card>
     
  );
};

export default MeasurementNotebook;
