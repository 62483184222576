import React, { useEffect, useState } from "react";
import moment from 'moment';
import BlogBreadcrumb from "../../components/BlogBreadcrumb";
import { Pagination, Avatar } from "antd";
import {getTopicPopularApi} from "../../services/contents"


const TrendingTopics = () => {
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalDataCount, setTotalDataCount] = useState(1)
  const [topicsPopular, setTopicsPopular] = useState<any>([]);

  const  breadcrumbItems = [
    { name: 'Anasayfa', url: '/' },
    { name: 'Trend Konular', url: '/trend-konular' },
  ];

  const getTopicPopular = async () => {
    try{
      const response:any = await getTopicPopularApi(currentPage,pageSize);
      if(response.status === 200){
        setTopicsPopular(response?.data?.topics);
        setTotalDataCount(response?.data?.totalDataCount)
      }
    }catch(e:any){
      console.log(e)
    }
  }

const handlePageChange = (page:any,size:any) => {
      setCurrentPage(page);
      setPageSize(size);
    };

useEffect(() => {
  getTopicPopular();
},[currentPage,pageSize])

  return (
    <>
     <BlogBreadcrumb breadcrumbItems={breadcrumbItems}  />
     <div className="topics-page ">
       <div className="topic-page-title" style={{marginBottom:'20px'}}>
        {/* <h3 style={{marginBottom:'5px'}}>{topicsInfo.subcategoryName} trens</h3> */}
        <p>
        2024 Yılı Tüp Bebek Fiyatları, Tüp bebek tedavisi nedir?, Tüp bebek tedavi aşamaları nelerdir?, Tüp bebek yöntemi nedir?, Tüp bebek uygulaması kaç gün sürer?, Embriyo Transferi, Tüp Bebek Nasıl Yapılır?, İnfertilite, Kısırlık Gebelik Oluşması İçin Ne Gibi Faktörler Olmalıdır?, İnfertilite Nedenleri?, Kısırlık Tedavisi, Tüp Bebek Doktorları, Tüp Bebek Hastaneleri, En iyi tüp bebek merkezleri
        </p>
      </div>
      <div style={{ textAlign: "left", marginTop: "20px" }}>
        <Pagination
         current={currentPage}
         pageSize={pageSize}
         total={totalDataCount}
         onChange={handlePageChange}
         showSizeChanger={true}
         pageSizeOptions={['10', '20', '50', '100']} 
        style={{marginBottom:'20px'}} 
        />
      </div>
      <div className="topic-list">
        {topicsPopular.map((topic:any) => (
          <div key={topic.id} className="box-card box-card-div box-card-article" >
            <div className="topic-info">
              <div className="topic-left">
                {
                  topic.topicCreatorProfilePhotoLink !== null ? 
                  <Avatar size={40} src={`https://api.kadincagundem.com/images/${topic.topicCreatorProfilePhotoLink}`} className="avatar" />
                    :
                  <Avatar size={40} icon={topic.topicCreatorUsername.slice(0, 1).toUpperCase()} />
                }
                <div className="topic-details">
                  <h3><a href={`/topics/${topic.topicSeoLink}`}>{topic.topicTitle}</a></h3>
                  <p>{moment(topic.topicCreatedDate).format('DD-MM-YYYY')}</p>
                  <p>{topic.topicCreatorUsername}</p>
                </div>
              </div>
              <div className="topic-center">
                <p>{`Yorumlar: ${topic.totalMessageCount}`}</p>
                <p>{`Görüntülenme: ${topic.totalViewCount}`}</p>
              </div>
              <div className="topic-right">
                <div style={{paddingRight:'10px'}}>
                  <p>{moment(topic.lastMessageDate).format('DD-MM-YYYY')}</p>
                  <p>{topic.lastMessageCreatorUsername}</p>
                </div>
                <div>
                  {
                  topic.lastMessageCreatorProfilePhotoLink !== null ? 
                  <Avatar size={30} src={`https://api.kadincagundem.com/images/${topic.lastMessageCreatorProfilePhotoLink}`} />
                    :
                  <Avatar size={30} icon={topic.lastMessageCreatorUsername.slice(0, 1).toUpperCase()} />
                }
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
     
    </div>
    </>
   
  );
};

export default TrendingTopics;