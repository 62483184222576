import React, { useState } from "react";

function MetaKeywordGenerator() {
  const [text, setText] = useState("");
  const [keywords, setKeywords] = useState("");

  // Anahtar kelimeleri belirleyen fonksiyon
  const generateKeywords = (inputText:any) => {
    if (!inputText) return "";

    // Yazıyı kelimelere ayır, küçük harfe çevir, özel karakterleri temizle
    let words = inputText
      .toLowerCase()
      .replace(/[.,!?🥹]/g, "")
      .split(/\s+/);

    // En sık kullanılan kelimeleri bul (basitçe en uzunları seçelim)
    let keywordSet = new Set(words.filter((word:any) => word.length > 3));

    // Virgülle ayırıp döndür
    return Array.from(keywordSet).join(", ");
  };

  // Metin değiştiğinde otomatik çalıştır
  const handleChange = (e:any) => {
    let newText = e.target.value;
    setText(newText);
    setKeywords(generateKeywords(newText));
  };

  return (
   <>
    <div className="p-4">
      <h2 className="text-lg font-bold mb-2">Meta Keywords Üretici</h2>
      <textarea
        className="w-full p-2 border rounded"
      
        placeholder="Metninizi buraya yazın..."
        value={text}
        onChange={handleChange}
      />
      <div className="mt-4 p-2 bg-gray-100 border rounded">
        <strong>Meta Keywords:</strong> {keywords || "Henüz kelime yok"}
      </div>
    </div>
   </>
  );
}

export default MetaKeywordGenerator;
